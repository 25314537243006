nav {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	width: 100%;
	padding: 1.5rem 8rem 0.2rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	background: var(--color-bg);
	z-index: 1;
}

.navbar-logo {
	flex: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.navbar-logo img {
	width: 130px;
	margin-right: 0.3rem;
}

.navbar-navigation {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

.navbar-links li {
	display: inline;
	padding: 0.4rem 0;
	margin-right: 3.5rem;
	-webkit-animation: navbar-link-animation 0.7s ease-in;
	animation: navbar-link-animation 0.7s ease-in;
}

.navbar-link-active {
	box-shadow: 0 -2px 0 0 inset var(--color-primary);
}

.navbar-links li a {
	font-size: 1.3rem;
	font-weight: 400;
}

.navbar-links li a span {
	color: var(--color-primary);
	font-weight: 500;
}

.navbar-links li:last-of-type {
	margin-right: 4rem;
}

@keyframes navbar-link-animation {
	0% {
		opacity: 0;
		transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.navbar-icon-sidebar,
.navbar-icon-all {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.navbar-icon-menu {
	cursor: pointer;
	color: var(--color-primary);
	transition: 0.3s all ease;
}

.navbar-icon-close {
	cursor: pointer;
	color: var(--color-clear);
	transition: 0.3s all ease;
}

.navbar-dark {
	background: var(--color-secondary);
}

.navbar-dark li a {
	color: var(--color-bg);
}

.navbar-dark li a:hover {
	color: var(--color-primary);
}

/* Small screen navbar modal  */

.navbar-modal-container {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--color-secondary);
	z-index: 3;
}

.navbar-modal-light {
	background: #f5f5f5;
}

.navbar-modal-close {
	display: flex;
	justify-content: flex-end;
}

.navbar-modal-close svg {
	color: var(--color-clear);
	margin: 2rem;
	cursor: pointer;
}

.scale-in-hor-center {
	-webkit-animation: scale-in-hor-center 0.6s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: scale-in-hor-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-hor-center {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		opacity: 1;
	}
}
@keyframes scale-in-hor-center {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		opacity: 1;
	}
}

@media screen and (max-width: 1366px) {
	.navbar-logo img {
		width: 100px;
	}

	.navbar-icon-menu,
	.navbar-icon-close {
		width: 28px;
		height: 28px;
	}

	.navbar-links li {
		margin-right: 2.8rem;
	}

	.navbar-links li:last-of-type {
		margin-right: 3.5rem;
	}

	.navbar-links li a {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 1150px) {
	nav {
		padding: 1.5rem 6rem;
	}

	.navbar-logo h4 {
		font-size: 22px;
	}
	.navbar-links li:last-of-type {
		margin-right: 3rem;
	}
}

@media screen and (max-width: 1024px) {
	nav {
		padding: 1.5rem 3rem;
	}

	.navbar-logo img {
		width: 105px;
	}

	.navbar-icon-menu,
	.navbar-icon-close {
		width: 26px;
		height: 26px;
	}

	.navbar-links li {
		margin-right: 2rem;
	}

	.navbar-links li a {
		font-size: 1rem;
	}
}

@media screen and (max-width: 900px) {
	nav {
		padding: 1.5rem 3rem;
	}
	.navbar-links li {
		margin-right: 1.8rem;
	}

	.navbar-links li:last-of-type {
		margin-right: 2rem;
	}
}

@media screen and (max-width: 768px) {
	nav {
		padding: 1.5rem 2rem;
	}

	.navbar-logo img {
		width: 90px;
	}
}

@media screen and (max-width: 700px) {
	.navbar-links li {
		margin-right: 0.8rem;
	}

	.navbar-links li:last-of-type {
		margin-right: 2rem;
	}
}

@media screen and (min-width: 600px) {
	.navbar-icon-sidebar {
		display: flex;
	}

	.navbar-icon-all {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	nav {
		padding: 1rem 2rem;
	}

	.navbar-links {
		display: none;
	}

	.navbar-icon-menu,
	.navbar-icon-close {
		width: 32px;
		height: 32px;
	}

	.navbar-icon-sidebar {
		display: none;
	}

	.navbar-icon-all {
		display: flex;
	}
}

@media screen and (max-width: 550px) {
	nav {
		padding: 1.5rem 1rem;
	}

	.navbar-logo {
		flex: 2;
	}

	.navbar-icon {
		flex: 1;
	}

	.navbar-logo img {
		width: 95px;
	}

	.navbar-icon-menu,
	.navbar-icon-close {
		width: 36px;
		height: 36px;
	}
}

@media screen and (max-width: 375px) {
	nav {
		padding: 1rem 1rem;
	}
}
