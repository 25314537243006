.resume {
	min-height: 84.5vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: var(--color-secondary);
}

.resume-container {
	display: flex;
	position: relative;
}

.resume-title {
	position: fixed;
	width: 20%;
	left: 8rem;
	padding: 2rem 0;
}

.resume-title-list li {
	width: 100%;
	font-size: 1.4rem;
	transition: 0.5s all ease;
}

.resume-title li:hover {
	background: rgba(110, 105, 105, 0.3);
}

.resume-title-work,
.resume-title-academics,
.resume-title-internship {
	font-size: 1.3rem;
	text-justify: inter-word;
	cursor: pointer;
	padding: 0.8rem 1.5rem;
	color: var(--color-bg);
}

.resume-title-list .active {
	background: rgba(110, 105, 105, 0.3);
	box-shadow: 1px 0 0 0 inset var(--color-primary);
}

.resume-title li:first-of-type {
	margin-top: 0;
}

.resume-title-container {
	flex: 1;
}

.resume-content {
	flex: 2.5;
}

.loader-container-resume {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: var(--color-secondary);
}

@media screen and (max-width: 1366px) {
	.resume-title-list li {
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 1024px) {
	.resume {
		min-height: 100vh;
	}

	.resume-title {
		width: 24%;
		left: 3rem;
	}

	.resume-title li {
		font-size: 1.2rem;
	}
}
@media screen and (max-width: 900px) {
	.resume-title li {
		font-size: 1.1em;
		padding: 0.6rem 0.8rem;
	}

	.resume-title {
		width: 28%;
		left: 2rem;
	}

	.resume-content {
		flex: 2.5;
	}
}

@media screen and (max-width: 700px) {
	.resume-container {
		flex-direction: column;
	}

	.resume-title {
		position: inherit;
		width: 100%;
		left: 0;
		padding: 1.2rem 0;
	}

	.resume-title-list {
		display: flex;
		flex-direction: row;
	}

	.resume-title-list li {
		font-size: 1rem;
		padding: 0.4rem 0rem;
		text-align: center;
		background: var(--color-text);
	}

	.resume-title-list .active {
		box-shadow: 0 -1px 0 0 inset var(--color-primary);
		background: rgba(110, 105, 105, 0.3);
	}
}

@media screen and (max-width: 550px) {
	.resume-title-list li {
		font-size: 1rem;
	}
}
