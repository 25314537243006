@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap);
:root {
	--font-family: 'Quicksand', sans-serif;
	--font-family-secondary: 'Roboto Mono', monospace;
	--color-bg: #ffffff;
	--color-text: #6e6969;
	--color-primary: #54ca95;
	--color-secondary: #3a3a3a;
	--color-clear: #f05340;
	--skill-indicator-load-time: 1s;
	--sidebar-load-time: 0.6s;
	--btn-hover-transition: 0.5s all;
	--link-hover-transition: 0.3s all;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

body {
	color: #6e6969;
	color: var(--color-text);
	font-family: 'Quicksand', sans-serif;
	font-family: var(--font-family);
	line-height: 1.6;
	letter-spacing: 0.5px;
	font-size: 1rem;
	box-sizing: border-box;
	background-color: #ffffff;
	background-color: var(--color-bg);
}

a {
	text-decoration: none;
	color: #6e6969;
	color: var(--color-text);
	font-weight: 500;
	font-size: 1.1rem;
	font-family: 'Roboto Mono', monospace;
	font-family: var(--font-family-secondary);
	transition: 0.3s all;
	transition: var(--link-hover-transition);
}

a:hover {
	color: #54ca95;
	color: var(--color-primary);
}

li {
	list-style-type: none;
}

p {
	font-size: 1rem;
	/* word-spacing: -2px; */
	text-align: justify;
	/* text-justify: inter-word; */
}

.button-primary {
	padding: 0.6rem 1.3rem;
	background: linear-gradient(
			to left,
			#3a3a3a 50%,
			#54ca95 50%
		)
		right;
	background: linear-gradient(
			to left,
			var(--color-secondary) 50%,
			var(--color-primary) 50%
		)
		right;
	border: none;
	background-size: 200%;
	color: #54ca95;
	color: var(--color-primary);
	font-family: 'Quicksand', sans-serif;
	font-family: var(--font-family);
	font-weight: 500;
	letter-spacing: 1px;
	border-radius: 5px;
	transition: 0.5s all;
	transition: var(--btn-hover-transition);
	cursor: pointer;
}

.button-primary:hover {
	background-position: left;
	color: #ffffff;
	color: var(--color-bg);
}

.button-secondary {
	padding: 0.6rem 1.3rem;
	background: linear-gradient(
			to left,
			transparent 50%,
			#54ca95 50%
		)
		right;
	background: linear-gradient(
			to left,
			transparent 50%,
			var(--color-primary) 50%
		)
		right;
	border: none;
	background-size: 200%;
	color: #54ca95;
	color: var(--color-primary);
	font-family: 'Quicksand', sans-serif;
	font-family: var(--font-family);
	font-weight: 500;
	letter-spacing: 1px;
	border-radius: 5px;
	box-shadow: 0px 0px 0px 1.5px #54ca95 inset;
	box-shadow: 0px 0px 0px 1.5px var(--color-primary) inset;
	transition: 0.5s all;
	transition: var(--btn-hover-transition);
	cursor: pointer;
}

.button-secondary:hover {
	background-position: left;
	outline: none;
	color: #f5f5f5;
}

.card-button {
	padding: 0.4rem 0.9rem;
	background: linear-gradient(
			to left,
			transparent 50%,
			#54ca95 50%
		)
		right;
	background: linear-gradient(
			to left,
			transparent 50%,
			var(--color-primary) 50%
		)
		right;
	font-size: 0.9rem;
	font-family: 'Quicksand', sans-serif;
	font-family: var(--font-family);
	border: none;
	background-size: 200%;
	color: #54ca95;
	color: var(--color-primary);
	font-weight: 500;
	letter-spacing: 1px;
	border-radius: 5px;
	box-shadow: 0px 0px 0px 1.5px #54ca95 inset;
	box-shadow: 0px 0px 0px 1.5px var(--color-primary) inset;
	transition: 0.5s all;
	transition: var(--btn-hover-transition);
	cursor: pointer;
}

.card-button:hover {
	background-position: left;
	outline: none;
	color: #f5f5f5;
}

.section_padding {
	padding: 1rem 8rem;
}

/* Sidebar Animation */
.slide-right {
	-webkit-animation: slide-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: slide-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@media screen and (max-width: 1366px) {
	.section_padding {
		padding: 2rem 8rem;
	}

	a {
		font-size: 1rem;
	}

	.button {
		padding: 0.5rem 1.1rem;
	}
}

@media screen and (max-width: 1150px) {
	.section_padding {
		padding: 2rem 6rem;
	}
}

@media screen and (max-width: 1024px) {
	.section_padding {
		padding: 2rem 3rem;
	}

	.button-primary,
	.button-secondary {
		font-size: 1rem;
		padding: 0.5rem 1rem;
	}

	.card-button {
		font-size: 0.8rem;
		padding: 0.4rem 0.6rem;
	}
}

@media screen and (max-width: 768px) {
	.section_padding {
		padding: 1rem 2rem;
	}

	body {
		line-height: 1.4;
	}

	p {
		font-size: 0.9rem;
	}

	.button-primary,
	.button-secondary {
		padding: 0.5rem 0.7rem;
		font-size: 1rem;
	}

	.card-button {
		font-size: 0.7rem;
		padding: 0.4rem 0.6rem;
	}
}

@media screen and (max-width: 550px) {
	.section_padding {
		padding: 1rem 1rem;
	}

	.button-primary,
	.button-secondary {
		padding: 0.5rem 0.5rem;
		font-size: 1rem;
	}
}

.header {
	display: flex;
	flex-direction: row;
	min-height: 84.5vh;
}

.header-content {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	margin-top: 5rem;
}

.header-content-intro {
	-webkit-animation: header-intro-animation 0.5s ease-in;
	animation: header-intro-animation 0.7s ease-in;
}

@-webkit-keyframes header-intro-animation {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-15px);
		        transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes header-intro-animation {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-15px);
		        transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.header-content-intro {
	display: block;
	font-size: 1.8rem;
	font-weight: 400;
}

.header-content h1 {
	font-family: var(--font-family-secondary);
	font-weight: 900;
	letter-spacing: 2px;
	word-spacing: -20px;
	overflow: hidden;
}

.header-content h1 span {
	position: relative;
	font-size: 65px;
	color: var(--color-primary);
	font-weight: 900;
}

.header-content h1 span::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
	border-left: 2px solid var(--color-primary);
	-webkit-animation: text-animation 3s infinite;
	animation: text-animation 3.5s steps(12) infinite;
}

@-webkit-keyframes text-animation {
	30%,
	70% {
		left: calc(100% + 5px);
	}
}

@keyframes text-animation {
	30%,
	70% {
		left: calc(100% + 5px);
	}
}

.header-content-designation {
	font-size: 2rem;
	margin: 0.2rem 0 2rem;
	font-weight: 500;
	-webkit-animation: header-designation-animation 1s ease-in;
	animation: header-designation-animation 1s ease-in;
}

@-webkit-keyframes header-designation-animation {
	0%,
	30% {
		opacity: 0;
		-webkit-transform: translateY(-15px);
		        transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes header-designation-animation {
	0%,
	30% {
		opacity: 0;
		-webkit-transform: translateY(-15px);
		        transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.header-content-description {
	font-size: 1.3rem;
	margin-bottom: 2.3rem;
	-webkit-animation: header-description-animation 1.2s ease-in;
	animation: header-description-animation 1.2s ease-in;
}

@-webkit-keyframes header-description-animation {
	0%,
	50% {
		opacity: 0;
		-webkit-transform: translateY(-10px);
		        transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes header-description-animation {
	0%,
	50% {
		opacity: 0;
		-webkit-transform: translateY(-10px);
		        transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.header-content-description span {
	color: var(--color-primary);
	font-weight: 900;
}

.header-btn {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.header-btn .button-primary,
.header-btn .button-secondary {
	margin-right: 1.2rem;
	-webkit-animation: header-button-animation 1s ease-in;
	animation: header-button-animation 1.5s ease-in;
}

@-webkit-keyframes header-button-animation {
	0%,
	60% {
		opacity: 0;
		-webkit-transform: translateY(-5px);
		        transform: translateY(-5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes header-button-animation {
	0%,
	60% {
		opacity: 0;
		-webkit-transform: translateY(-5px);
		        transform: translateY(-5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.header-image {
	flex: 1 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

.header-image img {
	width: 90%;
	height: 100%;
	-webkit-animation: header-image-animation 2s ease-in-out;
	        animation: header-image-animation 2s ease-in-out;
}

@-webkit-keyframes header-image-animation {
	0%,
	50% {
		opacity: 0;
		-webkit-transform: translateX(25px);
		        transform: translateX(25px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

@keyframes header-image-animation {
	0%,
	50% {
		opacity: 0;
		-webkit-transform: translateX(25px);
		        transform: translateX(25px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

.loader-container-header {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: #f5f5f5;
	z-index: 1;
}

@media screen and (max-width: 1366px) {
	.header {
		min-height: 85.3vh;
	}
	.header-content-intro {
		font-size: 1.6rem;
	}

	.header-content h1 span {
		font-size: 3.5rem;
	}

	.header-content-designation {
		font-size: 1.6rem;
		margin: 0.2rem 0 1.5rem;
	}

	.header-content-description {
		font-size: 1.1rem;
		margin-bottom: 2rem;
	}

	.header-btn .button-primary,
	.header-btn .button-secondary {
		margin-right: 1.3rem;
	}
}

@media screen and (max-width: 1024px) {
	.header {
		min-height: 100vh;
	}

	.header-content {
		margin-top: 0.5rem;
	}

	.header-content-intro {
		font-size: 1.2rem;
	}

	.header-content h1 {
		word-spacing: -15px;
	}

	.header-content h1 span {
		font-size: 2.8rem;
	}

	.header-content-designation {
		font-size: 1.4rem;
		margin: 0 0 1.2rem;
	}

	.header-content-description {
		margin-bottom: 1.6rem;
	}

	.header-image {
		height: 100%;
	}

	.header-image img {
		width: 90%;
	}
}

@media screen and (max-width: 900px) {
	.header {
		flex-direction: column;
	}

	.header-content {
		margin-top: 0;
	}

	.header-image {
		width: 100%;
		justify-content: center;
		margin-top: 1rem;
	}

	.header-image img {
		width: 80%;
	}

	.header-content-intro {
		font-size: 1rem;
	}

	.header-content h1 {
		word-spacing: -10px;
	}

	.header-content h1 span {
		font-size: 2.5rem;
	}

	.header-content h5 {
		margin: 0.3rem 0 1.2rem;
	}

	.header-content-designation {
		font-size: 1.3rem;
	}

	.header-btn .button-primary,
	.header-btn .button-secondary {
		margin-right: 1rem;
	}
}

@media screen and (max-width: 768px) {
	.header {
		min-height: 89vh;
	}

	.header-content-intro {
		font-size: 1.1rem;
	}

	.header-content h1 span {
		font-size: 2.3rem;
	}

	.header-content-designation {
		font-size: 1.2rem;
	}

	.header-image {
		width: 100%;
	}

	.header-content-description {
		font-size: 1rem;
	}
}

@media screen and (max-width: 550px) {
	.header-content {
		margin-top: 1.5rem;
	}

	.header-content-intro {
		font-size: 1.1rem;
	}

	.header-content h1 {
		word-spacing: -7px;
	}

	.header-image {
		display: none;
	}

	.header-content h1 span {
		font-size: 2rem;
	}

	.header-content-designation {
		font-size: 1.2rem;
		margin: 0.3rem 0 1.1rem;
	}

	.header-btn .button-primary,
	.header-btn .button-secondary {
		margin-right: 0.9rem;
	}
}

.loader {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color-text);
}

.loaderDark {
	color: var(--color-bg);
}

.loader-indicator {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	margin-right: 1rem;
	font-size: 3rem;
	color: var(--color-bg);
	border-radius: 5px;
}

.loader-indicator img {
	width: 100%;
}

.loader-page {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 120px;
	font-size: 6rem;
	font-weight: 600;
	text-transform: uppercase;
	font-family: var(--font-family-secondary);
	letter-spacing: 5px;
}

.loader-page-number {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 4rem;
	right: -7.8rem;
	padding: 0 1.2rem;
	font-size: 3.5rem;
	background: var(--color-primary);
	letter-spacing: normal;
	color: var(--color-bg);
	border-radius: 10px;
}

@media screen and (max-width: 1366px) {
	.loader-indicator {
		width: 100px;
		height: 100px;
	}
	.loader-page {
		height: 100px;
		font-size: 5rem;
	}

	.loader-page-number {
		top: 3.4rem;
		right: -6.2rem;
		font-size: 3rem;
		padding: 0 0.9rem;
	}
}

@media screen and (max-width: 1024px) {
	.loader {
		align-items: flex-start;
		padding-top: 25%;
	}

	.loader-indicator {
		width: 80px;
		height: 80px;
		margin-right: 0.5rem;
		border-radius: 5px;
	}

	.loader-page {
		height: 80px;
		font-size: 4.5rem;
		font-weight: 600;
	}

	.loader-page-number {
		top: 2.6rem;
		right: -4.6rem;
		padding: 0 0.6rem;
		font-size: 2.2rem;
	}
}

@media screen and (max-width: 768px) {
	.loader {
		padding-top: 35%;
	}

	.loader-indicator {
		width: 70px;
		height: 70px;
	}

	.loader-page {
		height: 70px;
		font-size: 3.5rem;
	}

	.loader-page-number {
		top: 2.4rem;
		right: -4rem;
		padding: 0 0.5rem;
		font-size: 2rem;
		border-radius: 5px;
	}
}

@media screen and (max-width: 600px) {
	.loader {
		padding-top: 45%;
	}
}

@media screen and (max-width: 550px) {
	.loader {
		padding-top: 65%;
	}

	.loader-indicator {
		width: 45px;
		height: 45px;
		margin-right: 0.3rem;
	}

	.loader-page {
		height: 45px;
		font-size: 2.5rem;
	}

	.loader-page-number {
		top: 1.6rem;
		right: -2.5rem;
		padding: 0 0.3rem;
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 375px) {
	.loader {
		padding-top: 70%;
	}

	.loader-indicator {
		width: 40px;
		height: 40px;
	}

	.loader-page {
		height: 40px;
		font-size: 1.8rem;
	}

	.loader-page-number {
		top: 1.3rem;
		right: -2.1rem;
		padding: 0.1rem 0.3rem;
		font-size: 1.2rem;
	}
}

.resume {
	min-height: 84.5vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: var(--color-secondary);
}

.resume-container {
	display: flex;
	position: relative;
}

.resume-title {
	position: fixed;
	width: 20%;
	left: 8rem;
	padding: 2rem 0;
}

.resume-title-list li {
	width: 100%;
	font-size: 1.4rem;
	transition: 0.5s all ease;
}

.resume-title li:hover {
	background: rgba(110, 105, 105, 0.3);
}

.resume-title-work,
.resume-title-academics,
.resume-title-internship {
	font-size: 1.3rem;
	text-justify: inter-word;
	cursor: pointer;
	padding: 0.8rem 1.5rem;
	color: var(--color-bg);
}

.resume-title-list .active {
	background: rgba(110, 105, 105, 0.3);
	box-shadow: 1px 0 0 0 inset var(--color-primary);
}

.resume-title li:first-of-type {
	margin-top: 0;
}

.resume-title-container {
	flex: 1 1;
}

.resume-content {
	flex: 2.5 1;
}

.loader-container-resume {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: var(--color-secondary);
}

@media screen and (max-width: 1366px) {
	.resume-title-list li {
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 1024px) {
	.resume {
		min-height: 100vh;
	}

	.resume-title {
		width: 24%;
		left: 3rem;
	}

	.resume-title li {
		font-size: 1.2rem;
	}
}
@media screen and (max-width: 900px) {
	.resume-title li {
		font-size: 1.1em;
		padding: 0.6rem 0.8rem;
	}

	.resume-title {
		width: 28%;
		left: 2rem;
	}

	.resume-content {
		flex: 2.5 1;
	}
}

@media screen and (max-width: 700px) {
	.resume-container {
		flex-direction: column;
	}

	.resume-title {
		position: inherit;
		width: 100%;
		left: 0;
		padding: 1.2rem 0;
	}

	.resume-title-list {
		display: flex;
		flex-direction: row;
	}

	.resume-title-list li {
		font-size: 1rem;
		padding: 0.4rem 0rem;
		text-align: center;
		background: var(--color-text);
	}

	.resume-title-list .active {
		box-shadow: 0 -1px 0 0 inset var(--color-primary);
		background: rgba(110, 105, 105, 0.3);
	}
}

@media screen and (max-width: 550px) {
	.resume-title-list li {
		font-size: 1rem;
	}
}

.resume-card {
	width: 100%;
	padding: 2rem 0rem 2rem 4rem;
	color: var(--color-bg);
}

.card-content-container {
	margin-top: 1.6rem;
}

.card-heading {
	font-size: 1.8rem;
	font-weight: 600;
	color: var(--color-primary);
}

.card-content {
	margin-bottom: 1.8rem;
	background: rgba(110, 105, 105, 0.3);
	padding: 1rem 1.5rem;
	-webkit-animation: resume-card-animation 0.4s ease-in;
	animation: resume-card-animation 0.6s ease-in;
}

.line {
	width: 100%;
	height: 0.1px;
	background: rgba(245, 245, 245, 0.5);
	/* -webkit-animation: resume-card-animation 0.5s ease-in;
	animation: resume-card-animation 0.5s ease-in; */
}

.card-content-title {
	font-size: 1.3rem;
	font-weight: 500;
	line-height: normal;
	color: var(--color-primary);
	-webkit-animation: resume-card-animation 0.8s ease-in;
	animation: resume-card-animation 0.8s ease-in;
}

.card-content-date {
	font-size: 1.1rem;
	margin-top: 0.3rem;
	font-weight: 500;
	-webkit-animation: resume-card-animation 0.8s ease-in;
	animation: resume-card-animation 0.8s ease-in;
}

.card-content-responsibility {
	margin-top: 0.5rem;
	font-size: 1.2rem;
	font-weight: 300;
	text-justify: inter-word;
	-webkit-animation: resume-card-animation 0.8s ease-in;
	animation: resume-card-animation 0.8s ease-in;
}

.responsibility-list {
	display: flex;
	justify-content: flex-start;
	margin-top: 0.6rem;
}

.responsibility-list-icon svg {
	color: var(--color-primary);
	width: 21px;
	height: 21px;
}

.responsibility-list-content {
	padding-left: 0.5rem;
}

.card-content-company {
	font-size: 1.1rem;
	font-weight: 600;
	margin-top: 0.7rem;
	-webkit-animation: resume-card-animation 1s ease-in;
	animation: resume-card-animation 0.9s ease-in;
}

@-webkit-keyframes resume-card-animation {
	0%,
	50% {
		-webkit-transform: translateY(5px);
		        transform: translateY(5px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		opacity: 1;
	}
}

@keyframes resume-card-animation {
	0%,
	50% {
		-webkit-transform: translateY(5px);
		        transform: translateY(5px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		opacity: 1;
	}
}

@media screen and (max-width: 1366px) {
	/* .card-content-container {
		height: 430px;
	} */

	.card-heading {
		font-size: 1.5rem;
	}

	.card-content {
		margin-bottom: 1.5rem;
	}

	.card-content-date {
		font-size: 1rem;
	}

	.card-content-title {
		font-size: 1.2rem;
	}

	.card-content-responsibility {
		font-size: 1rem;
	}

	.card-content-company {
		font-size: 1rem;
	}

	.responsibility-list-icon svg {
		width: 18px;
		height: 18px;
	}
}
@media screen and (max-width: 1024px) {
	.card-heading {
		font-size: 1.3rem;
	}

	.card-content-date {
		font-size: 1rem;
	}

	.card-content-title {
		font-size: 1.2rem;
		line-height: normal;
	}

	.card-content-responsibility {
		font-size: 1rem;
		margin-top: 0.4rem;
	}

	.card-content-company {
		font-size: 1rem;
	}

	.responsibility-list-icon svg {
		width: 17px;
		height: 17px;
	}
}

@media screen and (max-width: 900px) {
	.card-heading {
		font-size: 1.2rem;
	}

	/* .card-content-container {
		height: 440px;
		margin-top: 1.3rem;
	} */

	.card-content {
		margin-bottom: 1.3rem;
	}

	.card-content-date {
		font-size: 0.9rem;
	}

	.card-content-title {
		font-size: 1.1rem;
		line-height: normal;
	}

	.card-content-responsibility {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 700px) {
	.resume-card {
		padding: 0;
	}

	.card-content-container {
		margin-top: 0.6rem;
	}

	.card-content {
		margin-bottom: 1.4rem;
	}

	.card-heading,
	.line {
		display: none;
	}

	.card-content-date {
		margin-top: 0.3rem;
	}

	.card-content-responsibility {
		margin-top: 0.6rem;
		font-size: 1rem;
	}

	.responsibility-list {
		margin-top: 0.6rem;
	}
}

@media screen and (max-width: 550px) {
	/* .card-content-container {
		height: 400px;
	} */

	.card-heading {
		font-size: 1.2rem;
	}

	.card-content-title {
		font-size: 1.1rem;
	}

	.card-content-date {
		font-size: 1rem;
		margin-top: 0.3rem;
	}

	.card-content-responsibility {
		font-size: 1rem;
	}

	.card-content-company {
		font-size: 1rem;
	}

	.responsibility-list {
		margin-top: 0.6rem;
	}

	.responsibility-list-icon svg {
		width: 18px;
		height: 18px;
	}
}

@media screen and (max-width: 375px) {
	/* .card-content-container {
		height: 400px;
	} */

	.card-content {
		margin-bottom: 1.4rem;
	}
}

.portfolio {
	min-height: 88vh;
	color: var(--color-text);
	background: var(--color-bg);
}

.portfolio-category-navigation {
	top: 0;
	display: flex;
	width: 100%;
	margin: 1.5rem auto 2.5rem;
	justify-content: space-between;
	box-shadow: 0 -3px 0 0 inset rgba(110, 105, 105, 0.2);
}

.portfolio-category-all,
.portfolio-category-laravel,
.portfolio-category-react,
.portfolio-category-mern {
	align-items: center;
	width: 50%;
	font-size: 1.2rem;
	text-align: center;
	padding: 0.5rem 0;
	cursor: pointer;
	transition: 0.3s all ease;
	font-weight: 600;
	transition: 0.4s all ease;
}

.portfolio-category-laravel svg,
.portfolio-category-react svg,
.portfolio-category-mern svg {
	margin-right: 0.6rem;
}

.active-laravel svg {
	color: #f05340;
}

.active-react svg {
	color: #61dafb;
}

.active-mern svg {
	color: #68a063;
}

.active-all {
	box-shadow: 0 -3px 0 0 inset var(--color-primary);
}

.active-laravel {
	box-shadow: 0 -3px 0 0 inset #f05340;
}

.active-react {
	box-shadow: 0 -3px 0 0 inset #61dafb;
}

.active-mern {
	box-shadow: 0 -3px 0 0 inset#68a063;
}

.portfolio-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
	gap: 30px;
}

.loader-container-portfolio {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: #f5f5f5;
	z-index: 1;
}

@media screen and (max-width: 1366px) {
	.portfolio {
		min-height: 95vh;
	}

	.portfolio-category-all,
	.portfolio-category-laravel,
	.portfolio-category-react,
	.portfolio-category-mern {
		font-size: 1.1rem;
	}

	.portfolio-category-navigation {
		margin: 1rem auto 2rem;
	}
}

@media screen and (max-width: 1024px) {
	.portfolio {
		min-height: 100vh;
	}

	.portfolio-category-all,
	.portfolio-category-laravel,
	.portfolio-category-react,
	.portfolio-category-mern {
		font-size: 1rem;
	}

	.portfolio-category-navigation {
		margin: 0rem auto 2rem;
	}

	.portfolio-container {
		grid-gap: 30px;
	}
}

@media screen and (max-width: 768px) {
	.portfolio-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 700px) {
	.portfolio-container {
		grid-gap: 25px;
	}
}

@media screen and (max-width: 550px) {
	.portfolio-container {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 20px;
	}

	.portfolio-category-laravel svg,
	.portfolio-category-react svg,
	.portfolio-category-mern svg {
		display: none;
	}
}

.portfolio-details-modal-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 1rem;
	width: 100%;
}

.portfolio-details-modal-title-icon {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 60px;
}

.portfolio-details-modal-title-icon svg {
	margin-right: 1rem;
}

.portfolio-details-modal-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.portfolio-details-modal-title {
	font-size: 2.2rem;
	font-weight: 400;
	color: var(--color-primary);
	margin-bottom: 0.5rem;
	-webkit-animation: project-details-modal-animation 1s ease;
	animation: project-details-modal-animation 1s ease;
}

.portfolio-details-modal-description {
	font-size: 1.3rem;
	-webkit-animation: project-details-modal-animation 1.5s ease;
	animation: project-details-modal-animation 1.5s ease;
}

.portfolio-details-modal-btn {
	display: flex;
	justify-content: flex-start;
	margin: 2rem 0 5rem;
}

.portfolio-details-modal-btn a {
	margin-right: 1rem;
	text-align: center;
	line-height: 1.3;
	-webkit-animation: project-details-modal-animation 2s ease;
	animation: project-details-modal-animation 2s ease;
}

.portfolio-details-modal-snapshots {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
}

.portfolio-details-modal-snapshot img {
	width: 100%;
	-webkit-animation: project-details-modal-animation 1s ease;
	animation: project-details-modal-animation 2s ease;
	transition: 0.3s all ease;
}

.portfolio-details-modal-snapshot img:hover {
	-webkit-transform: scale(1.2, 1.2);
	        transform: scale(1.2, 1.2);
}

@-webkit-keyframes project-details-modal-animation {
	0%,
	50% {
		-webkit-transform: translateY(15px);
		        transform: translateY(15px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		opacity: 1;
	}
}

@keyframes project-details-modal-animation {
	0%,
	50% {
		-webkit-transform: translateY(15px);
		        transform: translateY(15px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		opacity: 1;
	}
}

@media screen and (max-width: 1366px) {
	.portfolio-details-modal-title-icon {
		height: 45px;
	}

	.portfolio-details-modal-title-icon svg {
		width: 28px;
		height: 28px;
	}

	.portfolio-details-modal-title {
		font-size: 1.8rem;
	}

	.portfolio-details-modal-description {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 1024px) {
	.portfolio-details-modal-btn {
		margin: 1.8rem 0 4rem;
	}

	.portfolio-details-modal-title-icon {
		height: 40px;
	}

	.portfolio-details-modal-title-icon svg {
		width: 24px;
		height: 24px;
	}

	.portfolio-details-modal-title {
		font-size: 1.5rem;
	}

	.portfolio-details-modal-description {
		font-size: 1.1rem;
	}

	.portfolio-details-modal-snapshots {
		grid-gap: 15px;
	}
}

@media screen and (max-width: 768px) {
	.portfolio-details-modal-title-icon {
		height: 34px;
	}

	.portfolio-details-modal-title-icon svg {
		width: 24px;
		height: 24px;
	}

	.portfolio-details-modal-btn {
		margin: 1.6rem 0 3.5rem;
	}

	.portfolio-details-modal-snapshots {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media screen and (max-width: 700px) {
	.portfolio-details-modal-title-icon {
		height: 26px;
	}

	.portfolio-details-modal-title-icon svg {
		width: 22px;
		height: 22px;
	}

	.portfolio-details-modal-title {
		font-size: 1.3rem;
	}

	.portfolio-details-modal-description {
		font-size: 1rem;
	}

	.portfolio-details-modal-snapshots {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 550px) {
	.portfolio-details-modal-title-icon {
		height: 30px;
	}

	.portfolio-details-modal-snapshots {
		grid-template-columns: repeat(2, 1fr);
	}

	.portfolio-details-modal-btn {
		margin: 1.5rem 0 2.5rem;
	}
}

.portfolio-card {
	position: relative;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	/* border: 1px solid rgba(110, 105, 105, 0.2); */
	min-height: 230px;
	border-radius: 10px;
	padding: 1.2rem 1.4rem;
	color: var(--color-text);
	box-shadow: 0.5px 1px 25px 2px rgba(0, 0, 0, 0.1);
	transition: 0.3s all ease;
	cursor: pointer;
	overflow: hidden;
	-webkit-animation: portfolio-card-animation 1s ease;
	        animation: portfolio-card-animation 1s ease;
}

@-webkit-keyframes portfolio-card-animation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes portfolio-card-animation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.portfolio-card-header {
	font-size: 1.4rem;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0.6rem;
}
.portfolio-card-header-icon svg {
	margin-right: 0.6rem;
}

.portfolio-card-content {
	display: flex;
	flex-direction: column;
}

.portfolio-card-text {
	font-size: 1.2rem;
	line-height: 1.5;
	font-weight: 400;
}

.portfolio-card-inner-modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
	width: 100%;
	height: 100%;
	left: -100%;
	top: 0;
	padding: 2rem 1.5rem;
	border-radius: 10px;
	background: var(--color-secondary);
	transition: 0.8s all ease;
}

.portfolio-card-inner-modal-icon {
	display: flex;
}

.portfolio-card-inner-modal-title {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: normal;
	align-items: center;
	font-size: 1.4rem;
	color: var(--color-bg);
	margin: 1rem 0rem 1.5rem;
}

.portfolio-card-inner-modal-btn {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.portfolio-card:hover > .portfolio-card-inner-modal {
	left: 0;
}

/* Project icon animation */
.project-category-icon-animation1 {
	-webkit-animation: project-catergory-icon-animation1 2s linear infinite both;
	animation: project-catergory-icon-animation1 2s linear infinite both;
}

@-webkit-keyframes project-catergory-icon-animation1 {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes project-catergory-icon-animation1 {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.project-category-icon-animation2 {
	-webkit-animation: project-category-icon-animation2 0.5s ease-in-out infinite
		both;
	animation: project-category-icon-animation2 0.5s ease-in-out infinite both;
}

@-webkit-keyframes project-category-icon-animation2 {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes project-category-icon-animation2 {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes project-category-icon-animation2 {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.project-details-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: var(--color-secondary);
	color: var(--color-bg);
	z-index: 2;
}

.project-details-modal-animation {
	-webkit-animation: project-details-modal-animation 0.6s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: project-details-modal-animation 0.6s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes project-details-modal-animation {
	0% {
		-webkit-transform: rotateX(80deg);
		transform: rotateX(80deg);
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
		opacity: 1;
	}
}

@keyframes project-details-modal-animation {
	0% {
		-webkit-transform: rotateX(80deg);
		transform: rotateX(80deg);
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
		opacity: 1;
	}
}

.modal-close {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.modal-close svg {
	cursor: pointer;
	color: var(--color-clear);
}

@media screen and (max-width: 1366px) {
	.portfolio-card {
		min-height: 200px;
		padding: 1.2rem 1.2rem;
	}

	.portfolio-card-header {
		font-size: 1.2rem;
	}

	.portfolio-card-text {
		font-size: 1.1rem;
	}

	.portfolio-card-inner-modal-icon svg {
		width: 32px;
		height: 32px;
	}

	.portfolio-card-inner-modal-title {
		font-size: 1.2rem;
		margin: 1rem 0rem 1rem;
	}

	.modal-close svg {
		width: 35px;
		height: 35px;
	}
}

@media screen and (max-width: 1024px) {
	.portfolio-card {
		min-height: 230px;
	}

	.portfolio-card-header {
		font-size: 1.1rem;
	}

	.portfolio-card-text {
		font-size: 1rem;
	}

	.portfolio-card-header-icon svg {
		width: 20px;
		height: 20px;
		margin-bottom: 4px;
	}

	.portfolio-card-inner-modal-icon svg {
		width: 30px;
		height: 30px;
	}

	.portfolio-card-inner-modal-title {
		font-size: 1.1rem;
	}

	.modal-close svg {
		width: 30px;
		height: 30px;
	}
}

@media screen and (max-width: 768px) {
	.portfolio-card {
		min-height: 180px;
	}

	.portfolio-card-inner-modal-icon svg {
		width: 28px;
		height: 28px;
	}

	/* .portfolio-card-inner-modal-title {
		font-size: 1rem;
	} */

	.portfolio-card-header-icon svg {
		width: 18px;
		height: 18px;
	}
}

@media screen and (max-width: 700px) {
	.portfolio-card {
		min-height: 160px;
	}

	.portfolio-card-inner-modal-title {
		font-size: 1rem;
		margin: 0.7rem 0;
	}

	.portfolio-card-header-icon svg {
		/* width: 16px;
		height: 16px; */
		margin-bottom: 0.2rem;
		margin-right: 0.3rem;
	}

	.portfolio-card-inner-modal-icon svg {
		width: 24px;
		height: 24px;
	}

	.modal-close svg {
		width: 25px;
		height: 25px;
	}
}

@media screen and (max-width: 550px) {
	.portfolio-card {
		min-height: 140px;
	}
}

.contact {
	min-height: 85.2vh;
	background: var(--color-secondary);
}

.contact-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 4rem;
}

.contact-info {
	flex: 1 1;
	width: 100%;
	display: flex;
	height: 55%;
	flex-direction: column;
	justify-content: center;
	color: var(--color-bg);
	box-shadow: 0 -1.5px 0 0 inset rgba(84, 202, 149, 0.6);
	-webkit-animation: contact-info-animation 1.5s ease-in-out;
	animation: contact-info-animation 1s ease-in-out;
}

.contact-info-heading {
	font-size: 3rem;
	letter-spacing: 1px;
	-webkit-animation: contact-info-animation 1s ease-in-out;
	animation: contact-info-animation 1s ease-in-out;
}

.contact-info-heading span {
	color: var(--color-primary);
}

.contact-info-text {
	font-size: 1.8rem;
	font-weight: 300;
	line-height: normal;
	margin-top: 0.5rem;
	-webkit-animation: contact-info-animation 1s ease-in-out;
	animation: contact-info-animation 1.5s ease-in-out;
}

.contact-info-details {
	margin-top: 3rem;
}

.contact-info-details ul {
	padding: 0;
	font-size: 1.3rem;
	font-weight: 200;
}

.contact-info-details li {
	-webkit-animation: contact-info-animation 1.6s ease-in-out;
	animation: contact-info-animation 1.6s ease-in-out;
}

.contact-info-details li:last-of-type {
	margin-top: 0.5rem;
}

.contact-info-details li span {
	color: var(--color-primary);
	font-weight: 400;
}

.contact-form {
	flex: 2 1;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
}

.contact-form-card {
	position: relative;
	width: 80%;
	display: flex;
	background: #2e2d2d;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	padding: 1.5rem 2.5rem;
	color: var(--color-primary);
	border-radius: 10px;
	-webkit-animation: contact-card-animation 2s ease-in-out;
	animation: contact-card-animation 1.5s ease-in-out;
	box-shadow: 1px 1px 5px 5px var(--color-secondary);
}

.contact-form-card form {
	width: 100%;
}

.contact-input {
	display: flex;
	width: 100%;
	flex-direction: column;
	margin: 1.3rem 0;
}

.contact-input input:focus {
	border-bottom: 2px solid rgba(84, 202, 149, 0.6);
}

.contact-input input:-webkit-autofill,
.contact-input input:-webkit-autofill:focus .contact-input {
	box-shadow: 0 0 0 30px #2e2d2d inset !important;
	border-bottom: 2px solid rgba(0, 0, 0, 0.6) !important;
	caret-color: rgba(245, 245, 245, 0.8) !important;
	-webkit-text-fill-color: rgba(245, 245, 245, 0.8) !important;
}

.contact-input label {
	font-size: 1.3rem;
	margin-bottom: 0.5rem;
	font-weight: 400;
}

.contact-input label span {
	color: var(--color-clear);
}

.contact-input input {
	width: 100%;
	background: transparent;
	outline: none;
	border: none;
	height: 1.5rem;
	border-bottom: 2px solid rgba(245, 245, 245, 0.2);
	color: rgba(245, 245, 245, 0.8);
	padding: 1rem 0;
	font-size: 1.2rem;
	font-weight: 300;
}

.contact-input textarea {
	background: transparent;
	outline: none;
	border: 2px solid rgba(245, 245, 245, 0.2);
	color: rgba(245, 245, 245, 0.8);
	border-radius: 5px;
	padding: 0.5rem;
	font-size: 1.2rem;
	font-weight: 200;
	transition: 0.3s all ease;
}

.contact-input textarea:focus {
	border: 2px solid rgba(84, 202, 149, 0.6);
}

.contact-submit {
	display: flex;
	width: 100%;
	text-align: center;
	margin: 1.8rem 0 0.5rem;
}

.contact-submit button {
	background: var(--color-secondary);
	font-size: 1.3rem;
	color: var(--color-primary);
	padding: 0.6rem 2.5rem;
	border: none;
	border-radius: 5px;
	transition: 0.3s all ease;
}

.contact-submit button:hover {
	background: var(--color-text);
	color: var(--color-bg);
}

@-webkit-keyframes contact-info-animation {
	0%,
	50% {
		opacity: 0;
		-webkit-transform: translateY(-15px);
		        transform: translateY(-15px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes contact-info-animation {
	0%,
	50% {
		opacity: 0;
		-webkit-transform: translateY(-15px);
		        transform: translateY(-15px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes contact-card-animation {
	0%,
	50% {
		opacity: 0;
		-webkit-transform: translateX(20px);
		        transform: translateX(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

@keyframes contact-card-animation {
	0%,
	50% {
		opacity: 0;
		-webkit-transform: translateX(20px);
		        transform: translateX(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

.loader-container-contact {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--color-secondary);
	z-index: 1;
}

@media screen and (max-width: 1366px) {
	.contact {
		min-height: 86vh;
	}

	.contact-container {
		margin-top: 1.2rem;
	}

	.contact-info-heading {
		font-size: 2.5rem;
	}

	.contact-info-text {
		font-size: 1.5rem;
	}

	.contact-info-details ul {
		font-size: 1.1rem;
	}

	.contact-info-details li svg {
		width: 21px;
		height: 21px;
	}

	.contact-info-details li:last-of-type svg {
		width: 24px;
		height: 24px;
	}

	.contact-input {
		margin: 1.2rem 0;
	}

	.contact-input label {
		font-size: 1.1rem;
	}

	.contact-input input {
		font-size: 1rem;
	}

	.contact-input textarea {
		font-size: 1rem;
	}

	.contact-submit button {
		font-size: 1.1rem;
		padding: 0.5rem 2.2rem;
	}
}

@media screen and (max-width: 1024px) {
	.contact {
		min-height: 100vh;
	}

	.contact-info-heading {
		font-size: 2rem;
	}

	.contact-info-text {
		font-size: 1.2rem;
	}

	.contact-info-details {
		margin-top: 2rem;
	}

	.contact-info-details ul {
		font-size: 1rem;
	}

	.contact-info-details li svg {
		width: 20px;
		height: 20px;
	}

	.contact-info-details li:last-of-type svg {
		width: 22px;
		height: 22px;
	}

	.contact-input {
		margin: 1rem 0;
	}

	.contact-input input {
		font-size: 1rem;
	}

	.contact-input textarea {
		font-size: 1rem;
	}

	.contact-submit button {
		font-size: 1.1rem;
		padding: 0.5rem 1.8rem;
	}
}

@media screen and (max-width: 768px) {
	.contact-container {
		margin-top: 1rem;
	}

	.contact-form {
		flex: 1.5 1;
	}

	.contact-form-card {
		width: 90%;
	}

	.contact-info-heading {
		font-size: 1.8rem;
	}

	.contact-info-text {
		font-size: 1.1rem;
	}

	.contact-info-details {
		margin-top: 2rem;
	}

	.contact-input label {
		font-size: 1rem;
	}

	.contact-submit button {
		font-size: 1rem;
		padding: 0.5rem 1.6rem;
	}
}

@media screen and (max-width: 700px) {
	.contact-container {
		flex-direction: column;
	}

	.contact-info {
		box-shadow: none;
	}

	.contact-form {
		margin-top: 1.5rem;
	}

	.contact-form-card {
		width: 100%;
	}

	.contact-info-text {
		margin-top: 0.3rem;
	}

	.contact-info-details {
		margin-top: 1rem;
	}

	.contact-info-details li {
		margin-top: 1rem;
	}

	.contact-info-details li:last-of-type {
		margin-top: 0.3rem;
	}

	.contact-form-card {
		padding: 1.2rem 2rem;
	}

	.contact-input {
		margin: 1.3rem 0;
	}
}

.alert-container {
	position: fixed;
	top: 0;
	left: 35%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: auto;
	height: auto;
	padding: 0.6rem 1.5rem;
	background: #f5f5f5;
	-webkit-animation: alert-message-show 2s ease-in-out;
	        animation: alert-message-show 2s ease-in-out;
	z-index: 2;
}

.success {
	box-shadow: 5px 0 0 0 inset var(--color-primary);
}

.error,
.network_error {
	box-shadow: 5px 0 0 0 inset var(--color-clear);
}

.alert-icon {
	display: flex;
	align-items: center;
}

.success svg {
	color: var(--color-primary);
	width: 32px;
	height: 32px;
}

.error svg,
.network_error svg {
	color: var(--color-clear);
	width: 32px;
	height: 32px;
}

.alert-message {
	margin-left: 0.7rem;
	font-size: 1.2rem;
	font-weight: 500;
}

@-webkit-keyframes alert-message-show {
	0% {
		top: -100%;
	}

	100% {
		top: 0;
	}
}

@keyframes alert-message-show {
	0% {
		top: -100%;
	}

	100% {
		top: 0;
	}
}

@media screen and (max-width: 1366px) {
	.alert-container {
		padding: 0.5rem 1.3rem;
	}

	.alert-icon svg {
		width: 26px;
		height: 26px;
	}

	.alert-message {
		font-size: 1rem;
	}
}

@media screen and (max-width: 1024px) {
	.alert-container {
		padding: 0.4rem 1rem;
		left: 35%;
	}

	.alert-icon svg {
		width: 25px;
		height: 25px;
	}
}

@media screen and (max-width: 768px) {
	.alert-container {
		padding: 0.4rem 0.8rem;
		left: 30%;
	}

	.alert-icon svg {
		width: 22px;
		height: 22px;
	}

	.alert-message {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 550px) {
	.alert-container {
		padding: 0.4rem 0.8rem;
		left: 15%;
	}
}

@media screen and (max-width: 375px) {
	.alert-container {
		padding: 0.4rem 0.8rem;
		left: 5%;
	}
}

.sidebar {
	position: fixed;
	left: 0;
	top: 0;
	background-color: #f5f5f5;
	color: var(--color-text);
	padding: 1.5rem 0rem 1rem;
	height: 100vh;
	transition: 0.5s ease-in all;
	box-shadow: 7px -2px 5px 0px rgba(0, 0, 0, 0.04);
	z-index: 2;
}

.active {
	margin-left: 0;
}

.sidebar {
	overflow-y: scroll;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 0;
}

.sidebar-close {
	padding: 1rem 1rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	display: none;
	min-height: 70px;
}

.sidebar-close svg {
	color: var(--color-clear);
	cursor: pointer;
	transition: 0.3s all ease;
}

.social-links {
	position: absolute;
	top: 0;
	left: 10px;
	width: 50px;
	height: 240px;
}

.vertical-line {
	width: 0.08rem;
	height: 2rem;
	background-color: var(--color-text);
	opacity: 0.5;
}

.social-links ul {
	margin: 0;
	padding: 10px 10px 10px;
}

.social-links li {
	margin-bottom: 0.2rem;
}

.social-links li a {
	font-size: 1.4rem;
	color: var(--color-text);
}

.social-links .social-icon {
	transition: 0.2s ease;
}

.social-links .social-icon:hover {
	-webkit-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
	color: #54ca95;
	transition: 0.2s ease;
}

.sidebar-profile {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: center;
}

.sidebar-profile-img {
	width: 40%;
	border-radius: 50%;
}

.sidebar-profile-name {
	font-size: 1.3rem;
	font-weight: 600;
}

.sidebar-profile-description {
	color: red;
	font-size: 1.1rem;
	font-weight: 500;
	margin-top: -0.8rem;
}

.sidebar-language-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #444444;
	border-right: 2px solid rgba(84, 202, 149, 0.6);
}

.sidebar-language {
	position: relative;
	width: 95px;
	height: 150px;
	color: #fff;
}

.sidebar-language svg {
	position: absolute;
	height: 160px;
	width: 160px;
	left: 50%;
	top: 43%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.sidebar-language svg circle {
	fill: transparent;
	stroke: #54ca95;
	stroke-width: 5;
	stroke-dasharray: 500;
	stroke-linecap: round;
	stroke-dashoffset: 0;
}

.sidebar-language svg circle[animation='1'] {
	-webkit-animation: stroke 3s steps(500) forwards;
	animation: stroke 3s steps(500) forwards;
}

/* language circle animation */
@-webkit-keyframes stroke {
	0% {
		stroke-dashoffset: 500;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes stroke {
	0% {
		stroke-dashoffset: 500;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

.sidebar-language .counter-1,
.sidebar-language .counter-2,
.sidebar-language .counter-3 {
	position: absolute;
	top: 43%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100%;
	height: 50px;
	text-align: center;
}

.sidebar-language .counter-1,
.sidebar-language .counter-2,
.sidebar-language .counter-3 {
	font-size: 0.9rem;
	line-height: 3rem;
}

.sidebar-language h6 {
	width: 100%;
	font-size: 1rem;
	font-weight: 400;
	position: absolute;
	text-align: center;
	bottom: 10px;
	letter-spacing: 0.1rem;
}

.sidebar-skills {
	display: flex;
	justify-content: center;
	text-align: center;
	padding: 35px 15px;
}

.sidebar-skills p {
	text-transform: capitalize;
	font-size: 1.1rem;
	font-weight: 500;
}

.sidebar-skills-language {
	display: flex;
	width: 100%;
	margin-bottom: 0.1rem;
}

.sidebar-skills-language .language {
	flex: 1 1;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.sidebar-skills-language .measure {
	flex: 4 1;
}

.sidebar-skills-language .progress {
	background-color: var(--color-secondary);
	height: 0.9rem;
	margin-top: 0.5rem;
	margin-bottom: 25px;
}

.sidebar-skills-language .progress-bar {
	background-color: #54ca95;
	height: 0.9rem;
	-webkit-transform-origin: left;
	        transform-origin: left;
}

.progress-bar[animation='1'] {
	-webkit-animation: skill-bar-load var(--skill-indicator-load-time) ease-in;
	animation: skill-bar-load var(--skill-indicator-load-time) ease-in;
}

@-webkit-keyframes skill-bar-load {
	0% {
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
	}
	100% {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}
}

@keyframes skill-bar-load {
	0% {
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
	}
	100% {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}
}

@media screen and (min-width: 1400px) {
	.sidebar {
		width: 380px;
		margin-left: -380px;
	}

	.active {
		margin-left: 0;
	}
}

@media screen and (max-width: 1400px) {
	.sidebar {
		width: 370px;
		margin-left: -370px;
	}

	.active {
		margin-left: 0;
	}
}

@media screen and (max-width: 1366px) {
	.sidebar-profile-name {
		font-size: 1.2rem;
	}

	.sidebar-profile-img {
		width: 35%;
	}

	.sidebar-profile-description {
		font-size: 1rem;
	}

	.sidebar-language .counter-1,
	.sidebar-language .counter-2,
	.sidebar-language .counter-3 {
		font-size: 0.8rem;
		line-height: 3rem;
	}

	.sidebar-language h6 {
		font-size: 0.9rem;
	}

	.sidebar-skills p {
		font-size: 1rem;
	}

	.sidebar-skills-language {
		margin-bottom: 0;
	}

	.sidebar-skills-language .progress {
		background-color: var(--color-secondary);
		height: 0.8rem;
		margin-top: 0.5rem;
	}

	.sidebar-skills-language .progress-bar {
		background-color: #54ca95;
		height: 0.8rem;
		-webkit-transform-origin: left;
		        transform-origin: left;
	}
}

@media screen and (max-width: 768px) {
	.sidebar {
		width: 310px;
		margin-left: -310px;
	}

	.active {
		margin-left: 0;
	}

	.social-links {
		position: absolute;
		top: 0;
		left: 5px;
		width: 50px;
		height: 200px;
	}

	.social-links li {
		margin-bottom: 0.2rem;
	}

	.social-links li a {
		font-size: 0.5rem;
	}

	.sidebar-profile-name {
		font-size: 1.1rem;
	}

	.sidebar-profile-description {
		font-size: 0.9rem;
	}

	.sidebar-language .counter-1,
	.sidebar-language .counter-2,
	.sidebar-language .counter-3 {
		font-size: 0.8rem;
		line-height: 3rem;
	}

	.sidebar-language h6 {
		font-size: 0.9rem;
	}

	.sidebar-skills p {
		font-size: 1rem;
	}

	.sidebar-skills-language .language {
		flex: 1.5 1;
		justify-content: flex-start;
	}

	.sidebar-skills-language .progress {
		background-color: var(--color-secondary);
		height: 0.7rem;
	}

	.sidebar-skills-language .progress-bar {
		background-color: #54ca95;
		height: 0.7rem;
		-webkit-transform-origin: left;
		        transform-origin: left;
	}
}

@media screen and (max-width: 700px) {
	.sidebar {
		width: 300px;
		margin-left: -300px;
	}

	.active {
		margin-left: 0;
	}

	.social-links {
		position: absolute;
		top: 0;
		left: 5px;
		width: 50px;
		height: 190px;
	}

	.social-links li {
		margin-bottom: 0.2rem;
	}

	.social-links li a {
		font-size: 0.1rem;
	}

	.sidebar-profile-name {
		font-size: 1.1rem;
	}

	.sidebar-profile-description {
		font-size: 0.9rem;
	}

	.sidebar-language .counter-1,
	.sidebar-language .counter-2,
	.sidebar-language .counter-3 {
		font-size: 0.8rem;
		line-height: 3rem;
	}

	.sidebar-language h6 {
		font-size: 0.9rem;
	}

	.sidebar-skills p {
		font-size: 1rem;
	}

	.sidebar-skills-language .progress {
		background-color: var(--color-secondary);
		height: 0.8rem;
	}

	.sidebar-skills-language .progress-bar {
		background-color: #54ca95;
		height: 0.8rem;
		-webkit-transform-origin: left;
		        transform-origin: left;
	}
}

@media screen and (max-width: 380px) {
	.sidebar {
		width: 100%;
		margin-left: -100%;
		padding-top: 0;
	}

	.active {
		margin-left: 0;
	}

	.sidebar-close {
		display: flex;
	}

	.navbar-icon-close {
		opacity: 0;
	}

	.social-links {
		position: absolute;
		top: 0;
		left: 15px;
		width: 50px;
		height: 210px;
	}

	.social-links li {
		margin-bottom: 0.4rem;
	}

	.social-links li a {
		font-size: 1.2rem;
	}

	.sidebar-profile-img {
		width: 42%;
	}

	.sidebar-profile-name {
		font-size: 1.2rem;
	}

	.sidebar-profile-description {
		font-size: 1rem;
	}

	.sidebar-language .counter-1,
	.sidebar-language .counter-2,
	.sidebar-language .counter-3 {
		font-size: 0.8rem;
		line-height: 3rem;
	}

	.sidebar-language {
		width: 26%;
	}

	.sidebar-language h6 {
		font-size: 1rem;
	}

	.sidebar-skills-language {
		display: flex;
		width: 100%;
		margin-bottom: 0.2rem;
	}

	.sidebar-skills-language .language {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.sidebar-skills-language .measure {
		flex: 4 1;
	}

	.sidebar-skills p {
		font-size: 1.1rem;
	}

	.progress {
		background-color: var(--color-secondary);
		height: 0.8rem;
	}

	.progress-bar {
		background-color: #54ca95;
		height: 0.8rem;
		-webkit-transform-origin: left;
		        transform-origin: left;
	}
}

nav {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	width: 100%;
	padding: 1.5rem 8rem 0.2rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	background: var(--color-bg);
	z-index: 1;
}

.navbar-logo {
	flex: 1 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.navbar-logo img {
	width: 130px;
	margin-right: 0.3rem;
}

.navbar-navigation {
	flex: 1 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

.navbar-links li {
	display: inline;
	padding: 0.4rem 0;
	margin-right: 3.5rem;
	-webkit-animation: navbar-link-animation 0.7s ease-in;
	animation: navbar-link-animation 0.7s ease-in;
}

.navbar-link-active {
	box-shadow: 0 -2px 0 0 inset var(--color-primary);
}

.navbar-links li a {
	font-size: 1.3rem;
	font-weight: 400;
}

.navbar-links li a span {
	color: var(--color-primary);
	font-weight: 500;
}

.navbar-links li:last-of-type {
	margin-right: 4rem;
}

@-webkit-keyframes navbar-link-animation {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-15px);
		        transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes navbar-link-animation {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-15px);
		        transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.navbar-icon-sidebar,
.navbar-icon-all {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.navbar-icon-menu {
	cursor: pointer;
	color: var(--color-primary);
	transition: 0.3s all ease;
}

.navbar-icon-close {
	cursor: pointer;
	color: var(--color-clear);
	transition: 0.3s all ease;
}

.navbar-dark {
	background: var(--color-secondary);
}

.navbar-dark li a {
	color: var(--color-bg);
}

.navbar-dark li a:hover {
	color: var(--color-primary);
}

/* Small screen navbar modal  */

.navbar-modal-container {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--color-secondary);
	z-index: 3;
}

.navbar-modal-light {
	background: #f5f5f5;
}

.navbar-modal-close {
	display: flex;
	justify-content: flex-end;
}

.navbar-modal-close svg {
	color: var(--color-clear);
	margin: 2rem;
	cursor: pointer;
}

.scale-in-hor-center {
	-webkit-animation: scale-in-hor-center 0.6s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: scale-in-hor-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-hor-center {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		opacity: 1;
	}
}
@keyframes scale-in-hor-center {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		opacity: 1;
	}
}

@media screen and (max-width: 1366px) {
	.navbar-logo img {
		width: 100px;
	}

	.navbar-icon-menu,
	.navbar-icon-close {
		width: 28px;
		height: 28px;
	}

	.navbar-links li {
		margin-right: 2.8rem;
	}

	.navbar-links li:last-of-type {
		margin-right: 3.5rem;
	}

	.navbar-links li a {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 1150px) {
	nav {
		padding: 1.5rem 6rem;
	}

	.navbar-logo h4 {
		font-size: 22px;
	}
	.navbar-links li:last-of-type {
		margin-right: 3rem;
	}
}

@media screen and (max-width: 1024px) {
	nav {
		padding: 1.5rem 3rem;
	}

	.navbar-logo img {
		width: 105px;
	}

	.navbar-icon-menu,
	.navbar-icon-close {
		width: 26px;
		height: 26px;
	}

	.navbar-links li {
		margin-right: 2rem;
	}

	.navbar-links li a {
		font-size: 1rem;
	}
}

@media screen and (max-width: 900px) {
	nav {
		padding: 1.5rem 3rem;
	}
	.navbar-links li {
		margin-right: 1.8rem;
	}

	.navbar-links li:last-of-type {
		margin-right: 2rem;
	}
}

@media screen and (max-width: 768px) {
	nav {
		padding: 1.5rem 2rem;
	}

	.navbar-logo img {
		width: 90px;
	}
}

@media screen and (max-width: 700px) {
	.navbar-links li {
		margin-right: 0.8rem;
	}

	.navbar-links li:last-of-type {
		margin-right: 2rem;
	}
}

@media screen and (min-width: 600px) {
	.navbar-icon-sidebar {
		display: flex;
	}

	.navbar-icon-all {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	nav {
		padding: 1rem 2rem;
	}

	.navbar-links {
		display: none;
	}

	.navbar-icon-menu,
	.navbar-icon-close {
		width: 32px;
		height: 32px;
	}

	.navbar-icon-sidebar {
		display: none;
	}

	.navbar-icon-all {
		display: flex;
	}
}

@media screen and (max-width: 550px) {
	nav {
		padding: 1.5rem 1rem;
	}

	.navbar-logo {
		flex: 2 1;
	}

	.navbar-icon {
		flex: 1 1;
	}

	.navbar-logo img {
		width: 95px;
	}

	.navbar-icon-menu,
	.navbar-icon-close {
		width: 36px;
		height: 36px;
	}
}

@media screen and (max-width: 375px) {
	nav {
		padding: 1rem 1rem;
	}
}

.navbar-modal-links {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 450px;
}

.navbar-modal-links a {
	color: #f5f5f5;
	font-size: 1.8rem;
	margin-top: 1.8rem;
	transition: 0.2s all ease-in-out;
	text-transform: uppercase;
	font-weight: 800;
	letter-spacing: 2px;
}

.navbar-modal-links-light a {
	color: var(--color-text);
}

.navbar-modal-links a:hover {
	color: var(--color-primary);
	-webkit-transform: translateY(-5px);
	        transform: translateY(-5px);
}

@media screen and (max-width: 550px) {
	.navbar-modal-links {
		height: 370px;
	}
	.navbar-modal-links a {
		font-size: 1.5rem;
		margin-top: 1.5rem;
	}
}

