.resume-card {
	width: 100%;
	padding: 2rem 0rem 2rem 4rem;
	color: var(--color-bg);
}

.card-content-container {
	margin-top: 1.6rem;
}

.card-heading {
	font-size: 1.8rem;
	font-weight: 600;
	color: var(--color-primary);
}

.card-content {
	margin-bottom: 1.8rem;
	background: rgba(110, 105, 105, 0.3);
	padding: 1rem 1.5rem;
	-webkit-animation: resume-card-animation 0.4s ease-in;
	animation: resume-card-animation 0.6s ease-in;
}

.line {
	width: 100%;
	height: 0.1px;
	background: rgba(245, 245, 245, 0.5);
	/* -webkit-animation: resume-card-animation 0.5s ease-in;
	animation: resume-card-animation 0.5s ease-in; */
}

.card-content-title {
	font-size: 1.3rem;
	font-weight: 500;
	line-height: normal;
	color: var(--color-primary);
	-webkit-animation: resume-card-animation 0.8s ease-in;
	animation: resume-card-animation 0.8s ease-in;
}

.card-content-date {
	font-size: 1.1rem;
	margin-top: 0.3rem;
	font-weight: 500;
	-webkit-animation: resume-card-animation 0.8s ease-in;
	animation: resume-card-animation 0.8s ease-in;
}

.card-content-responsibility {
	margin-top: 0.5rem;
	font-size: 1.2rem;
	font-weight: 300;
	text-justify: inter-word;
	-webkit-animation: resume-card-animation 0.8s ease-in;
	animation: resume-card-animation 0.8s ease-in;
}

.responsibility-list {
	display: flex;
	justify-content: flex-start;
	margin-top: 0.6rem;
}

.responsibility-list-icon svg {
	color: var(--color-primary);
	width: 21px;
	height: 21px;
}

.responsibility-list-content {
	padding-left: 0.5rem;
}

.card-content-company {
	font-size: 1.1rem;
	font-weight: 600;
	margin-top: 0.7rem;
	-webkit-animation: resume-card-animation 1s ease-in;
	animation: resume-card-animation 0.9s ease-in;
}

@keyframes resume-card-animation {
	0%,
	50% {
		transform: translateY(5px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@media screen and (max-width: 1366px) {
	/* .card-content-container {
		height: 430px;
	} */

	.card-heading {
		font-size: 1.5rem;
	}

	.card-content {
		margin-bottom: 1.5rem;
	}

	.card-content-date {
		font-size: 1rem;
	}

	.card-content-title {
		font-size: 1.2rem;
	}

	.card-content-responsibility {
		font-size: 1rem;
	}

	.card-content-company {
		font-size: 1rem;
	}

	.responsibility-list-icon svg {
		width: 18px;
		height: 18px;
	}
}
@media screen and (max-width: 1024px) {
	.card-heading {
		font-size: 1.3rem;
	}

	.card-content-date {
		font-size: 1rem;
	}

	.card-content-title {
		font-size: 1.2rem;
		line-height: normal;
	}

	.card-content-responsibility {
		font-size: 1rem;
		margin-top: 0.4rem;
	}

	.card-content-company {
		font-size: 1rem;
	}

	.responsibility-list-icon svg {
		width: 17px;
		height: 17px;
	}
}

@media screen and (max-width: 900px) {
	.card-heading {
		font-size: 1.2rem;
	}

	/* .card-content-container {
		height: 440px;
		margin-top: 1.3rem;
	} */

	.card-content {
		margin-bottom: 1.3rem;
	}

	.card-content-date {
		font-size: 0.9rem;
	}

	.card-content-title {
		font-size: 1.1rem;
		line-height: normal;
	}

	.card-content-responsibility {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 700px) {
	.resume-card {
		padding: 0;
	}

	.card-content-container {
		margin-top: 0.6rem;
	}

	.card-content {
		margin-bottom: 1.4rem;
	}

	.card-heading,
	.line {
		display: none;
	}

	.card-content-date {
		margin-top: 0.3rem;
	}

	.card-content-responsibility {
		margin-top: 0.6rem;
		font-size: 1rem;
	}

	.responsibility-list {
		margin-top: 0.6rem;
	}
}

@media screen and (max-width: 550px) {
	/* .card-content-container {
		height: 400px;
	} */

	.card-heading {
		font-size: 1.2rem;
	}

	.card-content-title {
		font-size: 1.1rem;
	}

	.card-content-date {
		font-size: 1rem;
		margin-top: 0.3rem;
	}

	.card-content-responsibility {
		font-size: 1rem;
	}

	.card-content-company {
		font-size: 1rem;
	}

	.responsibility-list {
		margin-top: 0.6rem;
	}

	.responsibility-list-icon svg {
		width: 18px;
		height: 18px;
	}
}

@media screen and (max-width: 375px) {
	/* .card-content-container {
		height: 400px;
	} */

	.card-content {
		margin-bottom: 1.4rem;
	}
}
