.sidebar {
	position: fixed;
	left: 0;
	top: 0;
	background-color: #f5f5f5;
	color: var(--color-text);
	padding: 1.5rem 0rem 1rem;
	height: 100vh;
	transition: 0.5s ease-in all;
	box-shadow: 7px -2px 5px 0px rgba(0, 0, 0, 0.04);
	z-index: 2;
}

.active {
	margin-left: 0;
}

.sidebar {
	overflow-y: scroll;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 0;
}

.sidebar-close {
	padding: 1rem 1rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	display: none;
	min-height: 70px;
}

.sidebar-close svg {
	color: var(--color-clear);
	cursor: pointer;
	transition: 0.3s all ease;
}

.social-links {
	position: absolute;
	top: 0;
	left: 10px;
	width: 50px;
	height: 240px;
}

.vertical-line {
	width: 0.08rem;
	height: 2rem;
	background-color: var(--color-text);
	opacity: 0.5;
}

.social-links ul {
	margin: 0;
	padding: 10px 10px 10px;
}

.social-links li {
	margin-bottom: 0.2rem;
}

.social-links li a {
	font-size: 1.4rem;
	color: var(--color-text);
}

.social-links .social-icon {
	transition: 0.2s ease;
}

.social-links .social-icon:hover {
	-webkit-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
	color: #54ca95;
	transition: 0.2s ease;
}

.sidebar-profile {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: center;
}

.sidebar-profile-img {
	width: 40%;
	border-radius: 50%;
}

.sidebar-profile-name {
	font-size: 1.3rem;
	font-weight: 600;
}

.sidebar-profile-description {
	color: red;
	font-size: 1.1rem;
	font-weight: 500;
	margin-top: -0.8rem;
}

.sidebar-language-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #444444;
	border-right: 2px solid rgba(84, 202, 149, 0.6);
}

.sidebar-language {
	position: relative;
	width: 95px;
	height: 150px;
	color: #fff;
}

.sidebar-language svg {
	position: absolute;
	height: 160px;
	width: 160px;
	left: 50%;
	top: 43%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.sidebar-language svg circle {
	fill: transparent;
	stroke: #54ca95;
	stroke-width: 5;
	stroke-dasharray: 500;
	stroke-linecap: round;
	stroke-dashoffset: 0;
}

.sidebar-language svg circle[animation='1'] {
	-webkit-animation: stroke 3s steps(500) forwards;
	animation: stroke 3s steps(500) forwards;
}

/* language circle animation */
@keyframes stroke {
	0% {
		stroke-dashoffset: 500;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

.sidebar-language .counter-1,
.sidebar-language .counter-2,
.sidebar-language .counter-3 {
	position: absolute;
	top: 43%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100%;
	height: 50px;
	text-align: center;
}

.sidebar-language .counter-1,
.sidebar-language .counter-2,
.sidebar-language .counter-3 {
	font-size: 0.9rem;
	line-height: 3rem;
}

.sidebar-language h6 {
	width: 100%;
	font-size: 1rem;
	font-weight: 400;
	position: absolute;
	text-align: center;
	bottom: 10px;
	letter-spacing: 0.1rem;
}

.sidebar-skills {
	display: flex;
	justify-content: center;
	text-align: center;
	padding: 35px 15px;
}

.sidebar-skills p {
	text-transform: capitalize;
	font-size: 1.1rem;
	font-weight: 500;
}

.sidebar-skills-language {
	display: flex;
	width: 100%;
	margin-bottom: 0.1rem;
}

.sidebar-skills-language .language {
	flex: 1;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.sidebar-skills-language .measure {
	flex: 4;
}

.sidebar-skills-language .progress {
	background-color: var(--color-secondary);
	height: 0.9rem;
	margin-top: 0.5rem;
	margin-bottom: 25px;
}

.sidebar-skills-language .progress-bar {
	background-color: #54ca95;
	height: 0.9rem;
	transform-origin: left;
}

.progress-bar[animation='1'] {
	-webkit-animation: skill-bar-load var(--skill-indicator-load-time) ease-in;
	animation: skill-bar-load var(--skill-indicator-load-time) ease-in;
}

@keyframes skill-bar-load {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

@media screen and (min-width: 1400px) {
	.sidebar {
		width: 380px;
		margin-left: -380px;
	}

	.active {
		margin-left: 0;
	}
}

@media screen and (max-width: 1400px) {
	.sidebar {
		width: 370px;
		margin-left: -370px;
	}

	.active {
		margin-left: 0;
	}
}

@media screen and (max-width: 1366px) {
	.sidebar-profile-name {
		font-size: 1.2rem;
	}

	.sidebar-profile-img {
		width: 35%;
	}

	.sidebar-profile-description {
		font-size: 1rem;
	}

	.sidebar-language .counter-1,
	.sidebar-language .counter-2,
	.sidebar-language .counter-3 {
		font-size: 0.8rem;
		line-height: 3rem;
	}

	.sidebar-language h6 {
		font-size: 0.9rem;
	}

	.sidebar-skills p {
		font-size: 1rem;
	}

	.sidebar-skills-language {
		margin-bottom: 0;
	}

	.sidebar-skills-language .progress {
		background-color: var(--color-secondary);
		height: 0.8rem;
		margin-top: 0.5rem;
	}

	.sidebar-skills-language .progress-bar {
		background-color: #54ca95;
		height: 0.8rem;
		transform-origin: left;
	}
}

@media screen and (max-width: 768px) {
	.sidebar {
		width: 310px;
		margin-left: -310px;
	}

	.active {
		margin-left: 0;
	}

	.social-links {
		position: absolute;
		top: 0;
		left: 5px;
		width: 50px;
		height: 200px;
	}

	.social-links li {
		margin-bottom: 0.2rem;
	}

	.social-links li a {
		font-size: 0.5rem;
	}

	.sidebar-profile-name {
		font-size: 1.1rem;
	}

	.sidebar-profile-description {
		font-size: 0.9rem;
	}

	.sidebar-language .counter-1,
	.sidebar-language .counter-2,
	.sidebar-language .counter-3 {
		font-size: 0.8rem;
		line-height: 3rem;
	}

	.sidebar-language h6 {
		font-size: 0.9rem;
	}

	.sidebar-skills p {
		font-size: 1rem;
	}

	.sidebar-skills-language .language {
		flex: 1.5;
		justify-content: flex-start;
	}

	.sidebar-skills-language .progress {
		background-color: var(--color-secondary);
		height: 0.7rem;
	}

	.sidebar-skills-language .progress-bar {
		background-color: #54ca95;
		height: 0.7rem;
		transform-origin: left;
	}
}

@media screen and (max-width: 700px) {
	.sidebar {
		width: 300px;
		margin-left: -300px;
	}

	.active {
		margin-left: 0;
	}

	.social-links {
		position: absolute;
		top: 0;
		left: 5px;
		width: 50px;
		height: 190px;
	}

	.social-links li {
		margin-bottom: 0.2rem;
	}

	.social-links li a {
		font-size: 0.1rem;
	}

	.sidebar-profile-name {
		font-size: 1.1rem;
	}

	.sidebar-profile-description {
		font-size: 0.9rem;
	}

	.sidebar-language .counter-1,
	.sidebar-language .counter-2,
	.sidebar-language .counter-3 {
		font-size: 0.8rem;
		line-height: 3rem;
	}

	.sidebar-language h6 {
		font-size: 0.9rem;
	}

	.sidebar-skills p {
		font-size: 1rem;
	}

	.sidebar-skills-language .progress {
		background-color: var(--color-secondary);
		height: 0.8rem;
	}

	.sidebar-skills-language .progress-bar {
		background-color: #54ca95;
		height: 0.8rem;
		transform-origin: left;
	}
}

@media screen and (max-width: 380px) {
	.sidebar {
		width: 100%;
		margin-left: -100%;
		padding-top: 0;
	}

	.active {
		margin-left: 0;
	}

	.sidebar-close {
		display: flex;
	}

	.navbar-icon-close {
		opacity: 0;
	}

	.social-links {
		position: absolute;
		top: 0;
		left: 15px;
		width: 50px;
		height: 210px;
	}

	.social-links li {
		margin-bottom: 0.4rem;
	}

	.social-links li a {
		font-size: 1.2rem;
	}

	.sidebar-profile-img {
		width: 42%;
	}

	.sidebar-profile-name {
		font-size: 1.2rem;
	}

	.sidebar-profile-description {
		font-size: 1rem;
	}

	.sidebar-language .counter-1,
	.sidebar-language .counter-2,
	.sidebar-language .counter-3 {
		font-size: 0.8rem;
		line-height: 3rem;
	}

	.sidebar-language {
		width: 26%;
	}

	.sidebar-language h6 {
		font-size: 1rem;
	}

	.sidebar-skills-language {
		display: flex;
		width: 100%;
		margin-bottom: 0.2rem;
	}

	.sidebar-skills-language .language {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.sidebar-skills-language .measure {
		flex: 4;
	}

	.sidebar-skills p {
		font-size: 1.1rem;
	}

	.progress {
		background-color: var(--color-secondary);
		height: 0.8rem;
	}

	.progress-bar {
		background-color: #54ca95;
		height: 0.8rem;
		transform-origin: left;
	}
}
