.navbar-modal-links {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 450px;
}

.navbar-modal-links a {
	color: #f5f5f5;
	font-size: 1.8rem;
	margin-top: 1.8rem;
	transition: 0.2s all ease-in-out;
	text-transform: uppercase;
	font-weight: 800;
	letter-spacing: 2px;
}

.navbar-modal-links-light a {
	color: var(--color-text);
}

.navbar-modal-links a:hover {
	color: var(--color-primary);
	transform: translateY(-5px);
}

@media screen and (max-width: 550px) {
	.navbar-modal-links {
		height: 370px;
	}
	.navbar-modal-links a {
		font-size: 1.5rem;
		margin-top: 1.5rem;
	}
}
