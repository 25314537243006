.contact {
	min-height: 85.2vh;
	background: var(--color-secondary);
}

.contact-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 4rem;
}

.contact-info {
	flex: 1;
	width: 100%;
	display: flex;
	height: 55%;
	flex-direction: column;
	justify-content: center;
	color: var(--color-bg);
	-webkit-box-shadow: 0 -1.5px 0 0 inset rgba(84, 202, 149, 0.6);
	box-shadow: 0 -1.5px 0 0 inset rgba(84, 202, 149, 0.6);
	-webkit-animation: contact-info-animation 1.5s ease-in-out;
	animation: contact-info-animation 1s ease-in-out;
}

.contact-info-heading {
	font-size: 3rem;
	letter-spacing: 1px;
	-webkit-animation: contact-info-animation 1s ease-in-out;
	animation: contact-info-animation 1s ease-in-out;
}

.contact-info-heading span {
	color: var(--color-primary);
}

.contact-info-text {
	font-size: 1.8rem;
	font-weight: 300;
	line-height: normal;
	margin-top: 0.5rem;
	-webkit-animation: contact-info-animation 1s ease-in-out;
	animation: contact-info-animation 1.5s ease-in-out;
}

.contact-info-details {
	margin-top: 3rem;
}

.contact-info-details ul {
	padding: 0;
	font-size: 1.3rem;
	font-weight: 200;
}

.contact-info-details li {
	-webkit-animation: contact-info-animation 1.6s ease-in-out;
	animation: contact-info-animation 1.6s ease-in-out;
}

.contact-info-details li:last-of-type {
	margin-top: 0.5rem;
}

.contact-info-details li span {
	color: var(--color-primary);
	font-weight: 400;
}

.contact-form {
	flex: 2;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
}

.contact-form-card {
	position: relative;
	width: 80%;
	display: flex;
	background: #2e2d2d;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	padding: 1.5rem 2.5rem;
	color: var(--color-primary);
	border-radius: 10px;
	-webkit-animation: contact-card-animation 2s ease-in-out;
	animation: contact-card-animation 1.5s ease-in-out;
	box-shadow: 1px 1px 5px 5px var(--color-secondary);
}

.contact-form-card form {
	width: 100%;
}

.contact-input {
	display: flex;
	width: 100%;
	flex-direction: column;
	margin: 1.3rem 0;
}

.contact-input input:focus {
	border-bottom: 2px solid rgba(84, 202, 149, 0.6);
}

.contact-input input:-webkit-autofill,
.contact-input input:-webkit-autofill:focus .contact-input {
	-webkit-box-shadow: 0 0 0 30px #2e2d2d inset !important;
	box-shadow: 0 0 0 30px #2e2d2d inset !important;
	border-bottom: 2px solid rgba(0, 0, 0, 0.6) !important;
	caret-color: rgba(245, 245, 245, 0.8) !important;
	-webkit-text-fill-color: rgba(245, 245, 245, 0.8) !important;
}

.contact-input label {
	font-size: 1.3rem;
	margin-bottom: 0.5rem;
	font-weight: 400;
}

.contact-input label span {
	color: var(--color-clear);
}

.contact-input input {
	width: 100%;
	background: transparent;
	outline: none;
	border: none;
	height: 1.5rem;
	border-bottom: 2px solid rgba(245, 245, 245, 0.2);
	color: rgba(245, 245, 245, 0.8);
	padding: 1rem 0;
	font-size: 1.2rem;
	font-weight: 300;
}

.contact-input textarea {
	background: transparent;
	outline: none;
	border: 2px solid rgba(245, 245, 245, 0.2);
	color: rgba(245, 245, 245, 0.8);
	border-radius: 5px;
	padding: 0.5rem;
	font-size: 1.2rem;
	font-weight: 200;
	transition: 0.3s all ease;
}

.contact-input textarea:focus {
	border: 2px solid rgba(84, 202, 149, 0.6);
}

.contact-submit {
	display: flex;
	width: 100%;
	text-align: center;
	margin: 1.8rem 0 0.5rem;
}

.contact-submit button {
	background: var(--color-secondary);
	font-size: 1.3rem;
	color: var(--color-primary);
	padding: 0.6rem 2.5rem;
	border: none;
	border-radius: 5px;
	transition: 0.3s all ease;
}

.contact-submit button:hover {
	background: var(--color-text);
	color: var(--color-bg);
}

@keyframes contact-info-animation {
	0%,
	50% {
		opacity: 0;
		transform: translateY(-15px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes contact-card-animation {
	0%,
	50% {
		opacity: 0;
		transform: translateX(20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.loader-container-contact {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--color-secondary);
	z-index: 1;
}

@media screen and (max-width: 1366px) {
	.contact {
		min-height: 86vh;
	}

	.contact-container {
		margin-top: 1.2rem;
	}

	.contact-info-heading {
		font-size: 2.5rem;
	}

	.contact-info-text {
		font-size: 1.5rem;
	}

	.contact-info-details ul {
		font-size: 1.1rem;
	}

	.contact-info-details li svg {
		width: 21px;
		height: 21px;
	}

	.contact-info-details li:last-of-type svg {
		width: 24px;
		height: 24px;
	}

	.contact-input {
		margin: 1.2rem 0;
	}

	.contact-input label {
		font-size: 1.1rem;
	}

	.contact-input input {
		font-size: 1rem;
	}

	.contact-input textarea {
		font-size: 1rem;
	}

	.contact-submit button {
		font-size: 1.1rem;
		padding: 0.5rem 2.2rem;
	}
}

@media screen and (max-width: 1024px) {
	.contact {
		min-height: 100vh;
	}

	.contact-info-heading {
		font-size: 2rem;
	}

	.contact-info-text {
		font-size: 1.2rem;
	}

	.contact-info-details {
		margin-top: 2rem;
	}

	.contact-info-details ul {
		font-size: 1rem;
	}

	.contact-info-details li svg {
		width: 20px;
		height: 20px;
	}

	.contact-info-details li:last-of-type svg {
		width: 22px;
		height: 22px;
	}

	.contact-input {
		margin: 1rem 0;
	}

	.contact-input input {
		font-size: 1rem;
	}

	.contact-input textarea {
		font-size: 1rem;
	}

	.contact-submit button {
		font-size: 1.1rem;
		padding: 0.5rem 1.8rem;
	}
}

@media screen and (max-width: 768px) {
	.contact-container {
		margin-top: 1rem;
	}

	.contact-form {
		flex: 1.5;
	}

	.contact-form-card {
		width: 90%;
	}

	.contact-info-heading {
		font-size: 1.8rem;
	}

	.contact-info-text {
		font-size: 1.1rem;
	}

	.contact-info-details {
		margin-top: 2rem;
	}

	.contact-input label {
		font-size: 1rem;
	}

	.contact-submit button {
		font-size: 1rem;
		padding: 0.5rem 1.6rem;
	}
}

@media screen and (max-width: 700px) {
	.contact-container {
		flex-direction: column;
	}

	.contact-info {
		box-shadow: none;
	}

	.contact-form {
		margin-top: 1.5rem;
	}

	.contact-form-card {
		width: 100%;
	}

	.contact-info-text {
		margin-top: 0.3rem;
	}

	.contact-info-details {
		margin-top: 1rem;
	}

	.contact-info-details li {
		margin-top: 1rem;
	}

	.contact-info-details li:last-of-type {
		margin-top: 0.3rem;
	}

	.contact-form-card {
		padding: 1.2rem 2rem;
	}

	.contact-input {
		margin: 1.3rem 0;
	}
}
