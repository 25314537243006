.alert-container {
	position: fixed;
	top: 0;
	left: 35%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: auto;
	height: auto;
	padding: 0.6rem 1.5rem;
	background: #f5f5f5;
	animation: alert-message-show 2s ease-in-out;
	z-index: 2;
}

.success {
	-webkit-box-shadow: 4px 0 0 0 inset var(--color-primary);
	box-shadow: 5px 0 0 0 inset var(--color-primary);
}

.error,
.network_error {
	-webkit-box-shadow: 2px 0 0 0 inset var(--color-clear);
	box-shadow: 5px 0 0 0 inset var(--color-clear);
}

.alert-icon {
	display: flex;
	align-items: center;
}

.success svg {
	color: var(--color-primary);
	width: 32px;
	height: 32px;
}

.error svg,
.network_error svg {
	color: var(--color-clear);
	width: 32px;
	height: 32px;
}

.alert-message {
	margin-left: 0.7rem;
	font-size: 1.2rem;
	font-weight: 500;
}

@keyframes alert-message-show {
	0% {
		top: -100%;
	}

	100% {
		top: 0;
	}
}

@media screen and (max-width: 1366px) {
	.alert-container {
		padding: 0.5rem 1.3rem;
	}

	.alert-icon svg {
		width: 26px;
		height: 26px;
	}

	.alert-message {
		font-size: 1rem;
	}
}

@media screen and (max-width: 1024px) {
	.alert-container {
		padding: 0.4rem 1rem;
		left: 35%;
	}

	.alert-icon svg {
		width: 25px;
		height: 25px;
	}
}

@media screen and (max-width: 768px) {
	.alert-container {
		padding: 0.4rem 0.8rem;
		left: 30%;
	}

	.alert-icon svg {
		width: 22px;
		height: 22px;
	}

	.alert-message {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 550px) {
	.alert-container {
		padding: 0.4rem 0.8rem;
		left: 15%;
	}
}

@media screen and (max-width: 375px) {
	.alert-container {
		padding: 0.4rem 0.8rem;
		left: 5%;
	}
}
