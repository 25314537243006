.portfolio {
	min-height: 88vh;
	color: var(--color-text);
	background: var(--color-bg);
}

.portfolio-category-navigation {
	top: 0;
	display: flex;
	width: 100%;
	margin: 1.5rem auto 2.5rem;
	justify-content: space-between;
	box-shadow: 0 -3px 0 0 inset rgba(110, 105, 105, 0.2);
}

.portfolio-category-all,
.portfolio-category-laravel,
.portfolio-category-react,
.portfolio-category-mern {
	align-items: center;
	width: 50%;
	font-size: 1.2rem;
	text-align: center;
	padding: 0.5rem 0;
	cursor: pointer;
	transition: 0.3s all ease;
	font-weight: 600;
	transition: 0.4s all ease;
}

.portfolio-category-laravel svg,
.portfolio-category-react svg,
.portfolio-category-mern svg {
	margin-right: 0.6rem;
}

.active-laravel svg {
	color: #f05340;
}

.active-react svg {
	color: #61dafb;
}

.active-mern svg {
	color: #68a063;
}

.active-all {
	-webkit-box-shadow: 0 -4px 0 0 inset var(--color-primary);
	box-shadow: 0 -3px 0 0 inset var(--color-primary);
}

.active-laravel {
	-webkit-box-shadow: 0 -4px 0 0 inset #f05340;
	box-shadow: 0 -3px 0 0 inset #f05340;
}

.active-react {
	-webkit-box-shadow: 0 -4px 0 0 inset #61dafb;
	box-shadow: 0 -3px 0 0 inset #61dafb;
}

.active-mern {
	-webkit-box-shadow: 0 -4px 0 0 inset #68a063;
	box-shadow: 0 -3px 0 0 inset#68a063;
}

.portfolio-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
}

.loader-container-portfolio {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: #f5f5f5;
	z-index: 1;
}

@media screen and (max-width: 1366px) {
	.portfolio {
		min-height: 95vh;
	}

	.portfolio-category-all,
	.portfolio-category-laravel,
	.portfolio-category-react,
	.portfolio-category-mern {
		font-size: 1.1rem;
	}

	.portfolio-category-navigation {
		margin: 1rem auto 2rem;
	}
}

@media screen and (max-width: 1024px) {
	.portfolio {
		min-height: 100vh;
	}

	.portfolio-category-all,
	.portfolio-category-laravel,
	.portfolio-category-react,
	.portfolio-category-mern {
		font-size: 1rem;
	}

	.portfolio-category-navigation {
		margin: 0rem auto 2rem;
	}

	.portfolio-container {
		grid-gap: 30px;
	}
}

@media screen and (max-width: 768px) {
	.portfolio-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 700px) {
	.portfolio-container {
		grid-gap: 25px;
	}
}

@media screen and (max-width: 550px) {
	.portfolio-container {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 20px;
	}

	.portfolio-category-laravel svg,
	.portfolio-category-react svg,
	.portfolio-category-mern svg {
		display: none;
	}
}
