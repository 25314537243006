@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap');

:root {
	--font-family: 'Quicksand', sans-serif;
	--font-family-secondary: 'Roboto Mono', monospace;
	--color-bg: #ffffff;
	--color-text: #6e6969;
	--color-primary: #54ca95;
	--color-secondary: #3a3a3a;
	--color-clear: #f05340;
	--skill-indicator-load-time: 1s;
	--sidebar-load-time: 0.6s;
	--btn-hover-transition: 0.5s all;
	--link-hover-transition: 0.3s all;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

body {
	color: var(--color-text);
	font-family: var(--font-family);
	line-height: 1.6;
	letter-spacing: 0.5px;
	font-size: 1rem;
	box-sizing: border-box;
	background-color: var(--color-bg);
}

a {
	text-decoration: none;
	color: var(--color-text);
	font-weight: 500;
	font-size: 1.1rem;
	font-family: var(--font-family-secondary);
	transition: var(--link-hover-transition);
}

a:hover {
	color: var(--color-primary);
}

li {
	list-style-type: none;
}

p {
	font-size: 1rem;
	/* word-spacing: -2px; */
	text-align: justify;
	/* text-justify: inter-word; */
}

.button-primary {
	padding: 0.6rem 1.3rem;
	background: linear-gradient(
			to left,
			var(--color-secondary) 50%,
			var(--color-primary) 50%
		)
		right;
	border: none;
	background-size: 200%;
	color: var(--color-primary);
	font-family: var(--font-family);
	font-weight: 500;
	letter-spacing: 1px;
	border-radius: 5px;
	transition: var(--btn-hover-transition);
	cursor: pointer;
}

.button-primary:hover {
	background-position: left;
	color: var(--color-bg);
}

.button-secondary {
	padding: 0.6rem 1.3rem;
	background: linear-gradient(
			to left,
			transparent 50%,
			var(--color-primary) 50%
		)
		right;
	border: none;
	background-size: 200%;
	color: var(--color-primary);
	font-family: var(--font-family);
	font-weight: 500;
	letter-spacing: 1px;
	border-radius: 5px;
	box-shadow: 0px 0px 0px 1.5px var(--color-primary) inset;
	transition: var(--btn-hover-transition);
	cursor: pointer;
}

.button-secondary:hover {
	background-position: left;
	outline: none;
	color: #f5f5f5;
}

.card-button {
	padding: 0.4rem 0.9rem;
	background: linear-gradient(
			to left,
			transparent 50%,
			var(--color-primary) 50%
		)
		right;
	font-size: 0.9rem;
	font-family: var(--font-family);
	border: none;
	background-size: 200%;
	color: var(--color-primary);
	font-weight: 500;
	letter-spacing: 1px;
	border-radius: 5px;
	box-shadow: 0px 0px 0px 1.5px var(--color-primary) inset;
	transition: var(--btn-hover-transition);
	cursor: pointer;
}

.card-button:hover {
	background-position: left;
	outline: none;
	color: #f5f5f5;
}

.section_padding {
	padding: 1rem 8rem;
}

/* Sidebar Animation */
.slide-right {
	-webkit-animation: slide-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: slide-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@media screen and (max-width: 1366px) {
	.section_padding {
		padding: 2rem 8rem;
	}

	a {
		font-size: 1rem;
	}

	.button {
		padding: 0.5rem 1.1rem;
	}
}

@media screen and (max-width: 1150px) {
	.section_padding {
		padding: 2rem 6rem;
	}
}

@media screen and (max-width: 1024px) {
	.section_padding {
		padding: 2rem 3rem;
	}

	.button-primary,
	.button-secondary {
		font-size: 1rem;
		padding: 0.5rem 1rem;
	}

	.card-button {
		font-size: 0.8rem;
		padding: 0.4rem 0.6rem;
	}
}

@media screen and (max-width: 768px) {
	.section_padding {
		padding: 1rem 2rem;
	}

	body {
		line-height: 1.4;
	}

	p {
		font-size: 0.9rem;
	}

	.button-primary,
	.button-secondary {
		padding: 0.5rem 0.7rem;
		font-size: 1rem;
	}

	.card-button {
		font-size: 0.7rem;
		padding: 0.4rem 0.6rem;
	}
}

@media screen and (max-width: 550px) {
	.section_padding {
		padding: 1rem 1rem;
	}

	.button-primary,
	.button-secondary {
		padding: 0.5rem 0.5rem;
		font-size: 1rem;
	}
}
