.portfolio-card {
	position: relative;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	/* border: 1px solid rgba(110, 105, 105, 0.2); */
	min-height: 230px;
	border-radius: 10px;
	padding: 1.2rem 1.4rem;
	color: var(--color-text);
	box-shadow: 0.5px 1px 25px 2px rgba(0, 0, 0, 0.1);
	transition: 0.3s all ease;
	cursor: pointer;
	overflow: hidden;
	animation: portfolio-card-animation 1s ease;
}

@keyframes portfolio-card-animation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.portfolio-card-header {
	font-size: 1.4rem;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0.6rem;
}
.portfolio-card-header-icon svg {
	margin-right: 0.6rem;
}

.portfolio-card-content {
	display: flex;
	flex-direction: column;
}

.portfolio-card-text {
	font-size: 1.2rem;
	line-height: 1.5;
	font-weight: 400;
}

.portfolio-card-inner-modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
	width: 100%;
	height: 100%;
	left: -100%;
	top: 0;
	padding: 2rem 1.5rem;
	border-radius: 10px;
	background: var(--color-secondary);
	transition: 0.8s all ease;
}

.portfolio-card-inner-modal-icon {
	display: flex;
}

.portfolio-card-inner-modal-title {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: normal;
	align-items: center;
	font-size: 1.4rem;
	color: var(--color-bg);
	margin: 1rem 0rem 1.5rem;
}

.portfolio-card-inner-modal-btn {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.portfolio-card:hover > .portfolio-card-inner-modal {
	left: 0;
}

/* Project icon animation */
.project-category-icon-animation1 {
	-webkit-animation: project-catergory-icon-animation1 2s linear infinite both;
	animation: project-catergory-icon-animation1 2s linear infinite both;
}

@keyframes project-catergory-icon-animation1 {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.project-category-icon-animation2 {
	-webkit-animation: project-category-icon-animation2 0.5s ease-in-out infinite
		both;
	animation: project-category-icon-animation2 0.5s ease-in-out infinite both;
}

@keyframes project-category-icon-animation2 {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes project-category-icon-animation2 {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.project-details-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: var(--color-secondary);
	color: var(--color-bg);
	z-index: 2;
}

.project-details-modal-animation {
	-webkit-animation: project-details-modal-animation 0.6s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: project-details-modal-animation 0.6s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes project-details-modal-animation {
	0% {
		-webkit-transform: rotateX(80deg);
		transform: rotateX(80deg);
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
		opacity: 1;
	}
}

.modal-close {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.modal-close svg {
	cursor: pointer;
	color: var(--color-clear);
}

@media screen and (max-width: 1366px) {
	.portfolio-card {
		min-height: 200px;
		padding: 1.2rem 1.2rem;
	}

	.portfolio-card-header {
		font-size: 1.2rem;
	}

	.portfolio-card-text {
		font-size: 1.1rem;
	}

	.portfolio-card-inner-modal-icon svg {
		width: 32px;
		height: 32px;
	}

	.portfolio-card-inner-modal-title {
		font-size: 1.2rem;
		margin: 1rem 0rem 1rem;
	}

	.modal-close svg {
		width: 35px;
		height: 35px;
	}
}

@media screen and (max-width: 1024px) {
	.portfolio-card {
		min-height: 230px;
	}

	.portfolio-card-header {
		font-size: 1.1rem;
	}

	.portfolio-card-text {
		font-size: 1rem;
	}

	.portfolio-card-header-icon svg {
		width: 20px;
		height: 20px;
		margin-bottom: 4px;
	}

	.portfolio-card-inner-modal-icon svg {
		width: 30px;
		height: 30px;
	}

	.portfolio-card-inner-modal-title {
		font-size: 1.1rem;
	}

	.modal-close svg {
		width: 30px;
		height: 30px;
	}
}

@media screen and (max-width: 768px) {
	.portfolio-card {
		min-height: 180px;
	}

	.portfolio-card-inner-modal-icon svg {
		width: 28px;
		height: 28px;
	}

	/* .portfolio-card-inner-modal-title {
		font-size: 1rem;
	} */

	.portfolio-card-header-icon svg {
		width: 18px;
		height: 18px;
	}
}

@media screen and (max-width: 700px) {
	.portfolio-card {
		min-height: 160px;
	}

	.portfolio-card-inner-modal-title {
		font-size: 1rem;
		margin: 0.7rem 0;
	}

	.portfolio-card-header-icon svg {
		/* width: 16px;
		height: 16px; */
		margin-bottom: 0.2rem;
		margin-right: 0.3rem;
	}

	.portfolio-card-inner-modal-icon svg {
		width: 24px;
		height: 24px;
	}

	.modal-close svg {
		width: 25px;
		height: 25px;
	}
}

@media screen and (max-width: 550px) {
	.portfolio-card {
		min-height: 140px;
	}
}
