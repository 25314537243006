.loader {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color-text);
}

.loaderDark {
	color: var(--color-bg);
}

.loader-indicator {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	margin-right: 1rem;
	font-size: 3rem;
	color: var(--color-bg);
	border-radius: 5px;
}

.loader-indicator img {
	width: 100%;
}

.loader-page {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 120px;
	font-size: 6rem;
	font-weight: 600;
	text-transform: uppercase;
	font-family: var(--font-family-secondary);
	letter-spacing: 5px;
}

.loader-page-number {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 4rem;
	right: -7.8rem;
	padding: 0 1.2rem;
	font-size: 3.5rem;
	background: var(--color-primary);
	letter-spacing: normal;
	color: var(--color-bg);
	border-radius: 10px;
}

@media screen and (max-width: 1366px) {
	.loader-indicator {
		width: 100px;
		height: 100px;
	}
	.loader-page {
		height: 100px;
		font-size: 5rem;
	}

	.loader-page-number {
		top: 3.4rem;
		right: -6.2rem;
		font-size: 3rem;
		padding: 0 0.9rem;
	}
}

@media screen and (max-width: 1024px) {
	.loader {
		align-items: flex-start;
		padding-top: 25%;
	}

	.loader-indicator {
		width: 80px;
		height: 80px;
		margin-right: 0.5rem;
		border-radius: 5px;
	}

	.loader-page {
		height: 80px;
		font-size: 4.5rem;
		font-weight: 600;
	}

	.loader-page-number {
		top: 2.6rem;
		right: -4.6rem;
		padding: 0 0.6rem;
		font-size: 2.2rem;
	}
}

@media screen and (max-width: 768px) {
	.loader {
		padding-top: 35%;
	}

	.loader-indicator {
		width: 70px;
		height: 70px;
	}

	.loader-page {
		height: 70px;
		font-size: 3.5rem;
	}

	.loader-page-number {
		top: 2.4rem;
		right: -4rem;
		padding: 0 0.5rem;
		font-size: 2rem;
		border-radius: 5px;
	}
}

@media screen and (max-width: 600px) {
	.loader {
		padding-top: 45%;
	}
}

@media screen and (max-width: 550px) {
	.loader {
		padding-top: 65%;
	}

	.loader-indicator {
		width: 45px;
		height: 45px;
		margin-right: 0.3rem;
	}

	.loader-page {
		height: 45px;
		font-size: 2.5rem;
	}

	.loader-page-number {
		top: 1.6rem;
		right: -2.5rem;
		padding: 0 0.3rem;
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 375px) {
	.loader {
		padding-top: 70%;
	}

	.loader-indicator {
		width: 40px;
		height: 40px;
	}

	.loader-page {
		height: 40px;
		font-size: 1.8rem;
	}

	.loader-page-number {
		top: 1.3rem;
		right: -2.1rem;
		padding: 0.1rem 0.3rem;
		font-size: 1.2rem;
	}
}
