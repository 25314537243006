.header {
	display: flex;
	flex-direction: row;
	min-height: 84.5vh;
}

.header-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	margin-top: 5rem;
}

.header-content-intro {
	-webkit-animation: header-intro-animation 0.5s ease-in;
	animation: header-intro-animation 0.7s ease-in;
}

@keyframes header-intro-animation {
	0% {
		opacity: 0;
		transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.header-content-intro {
	display: block;
	font-size: 1.8rem;
	font-weight: 400;
}

.header-content h1 {
	font-family: var(--font-family-secondary);
	font-weight: 900;
	letter-spacing: 2px;
	word-spacing: -20px;
	overflow: hidden;
}

.header-content h1 span {
	position: relative;
	font-size: 65px;
	color: var(--color-primary);
	font-weight: 900;
}

.header-content h1 span::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
	border-left: 2px solid var(--color-primary);
	-webkit-animation: text-animation 3s infinite;
	animation: text-animation 3.5s steps(12) infinite;
}

@keyframes text-animation {
	30%,
	70% {
		left: calc(100% + 5px);
	}
}

.header-content-designation {
	font-size: 2rem;
	margin: 0.2rem 0 2rem;
	font-weight: 500;
	-webkit-animation: header-designation-animation 1s ease-in;
	animation: header-designation-animation 1s ease-in;
}

@keyframes header-designation-animation {
	0%,
	30% {
		opacity: 0;
		transform: translateY(-15px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.header-content-description {
	font-size: 1.3rem;
	margin-bottom: 2.3rem;
	-webkit-animation: header-description-animation 1.2s ease-in;
	animation: header-description-animation 1.2s ease-in;
}

@keyframes header-description-animation {
	0%,
	50% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.header-content-description span {
	color: var(--color-primary);
	font-weight: 900;
}

.header-btn {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.header-btn .button-primary,
.header-btn .button-secondary {
	margin-right: 1.2rem;
	-webkit-animation: header-button-animation 1s ease-in;
	animation: header-button-animation 1.5s ease-in;
}

@keyframes header-button-animation {
	0%,
	60% {
		opacity: 0;
		transform: translateY(-5px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.header-image {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

.header-image img {
	width: 90%;
	height: 100%;
	animation: header-image-animation 2s ease-in-out;
}

@keyframes header-image-animation {
	0%,
	50% {
		opacity: 0;
		transform: translateX(25px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.loader-container-header {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: #f5f5f5;
	z-index: 1;
}

@media screen and (max-width: 1366px) {
	.header {
		min-height: 85.3vh;
	}
	.header-content-intro {
		font-size: 1.6rem;
	}

	.header-content h1 span {
		font-size: 3.5rem;
	}

	.header-content-designation {
		font-size: 1.6rem;
		margin: 0.2rem 0 1.5rem;
	}

	.header-content-description {
		font-size: 1.1rem;
		margin-bottom: 2rem;
	}

	.header-btn .button-primary,
	.header-btn .button-secondary {
		margin-right: 1.3rem;
	}
}

@media screen and (max-width: 1024px) {
	.header {
		min-height: 100vh;
	}

	.header-content {
		margin-top: 0.5rem;
	}

	.header-content-intro {
		font-size: 1.2rem;
	}

	.header-content h1 {
		word-spacing: -15px;
	}

	.header-content h1 span {
		font-size: 2.8rem;
	}

	.header-content-designation {
		font-size: 1.4rem;
		margin: 0 0 1.2rem;
	}

	.header-content-description {
		margin-bottom: 1.6rem;
	}

	.header-image {
		height: 100%;
	}

	.header-image img {
		width: 90%;
	}
}

@media screen and (max-width: 900px) {
	.header {
		flex-direction: column;
	}

	.header-content {
		margin-top: 0;
	}

	.header-image {
		width: 100%;
		justify-content: center;
		margin-top: 1rem;
	}

	.header-image img {
		width: 80%;
	}

	.header-content-intro {
		font-size: 1rem;
	}

	.header-content h1 {
		word-spacing: -10px;
	}

	.header-content h1 span {
		font-size: 2.5rem;
	}

	.header-content h5 {
		margin: 0.3rem 0 1.2rem;
	}

	.header-content-designation {
		font-size: 1.3rem;
	}

	.header-btn .button-primary,
	.header-btn .button-secondary {
		margin-right: 1rem;
	}
}

@media screen and (max-width: 768px) {
	.header {
		min-height: 89vh;
	}

	.header-content-intro {
		font-size: 1.1rem;
	}

	.header-content h1 span {
		font-size: 2.3rem;
	}

	.header-content-designation {
		font-size: 1.2rem;
	}

	.header-image {
		width: 100%;
	}

	.header-content-description {
		font-size: 1rem;
	}
}

@media screen and (max-width: 550px) {
	.header-content {
		margin-top: 1.5rem;
	}

	.header-content-intro {
		font-size: 1.1rem;
	}

	.header-content h1 {
		word-spacing: -7px;
	}

	.header-image {
		display: none;
	}

	.header-content h1 span {
		font-size: 2rem;
	}

	.header-content-designation {
		font-size: 1.2rem;
		margin: 0.3rem 0 1.1rem;
	}

	.header-btn .button-primary,
	.header-btn .button-secondary {
		margin-right: 0.9rem;
	}
}
