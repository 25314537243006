.portfolio-details-modal-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 1rem;
	width: 100%;
}

.portfolio-details-modal-title-icon {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 60px;
}

.portfolio-details-modal-title-icon svg {
	margin-right: 1rem;
}

.portfolio-details-modal-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.portfolio-details-modal-title {
	font-size: 2.2rem;
	font-weight: 400;
	color: var(--color-primary);
	margin-bottom: 0.5rem;
	-webkit-animation: project-details-modal-animation 1s ease;
	animation: project-details-modal-animation 1s ease;
}

.portfolio-details-modal-description {
	font-size: 1.3rem;
	-webkit-animation: project-details-modal-animation 1.5s ease;
	animation: project-details-modal-animation 1.5s ease;
}

.portfolio-details-modal-btn {
	display: flex;
	justify-content: flex-start;
	margin: 2rem 0 5rem;
}

.portfolio-details-modal-btn a {
	margin-right: 1rem;
	text-align: center;
	line-height: 1.3;
	-webkit-animation: project-details-modal-animation 2s ease;
	animation: project-details-modal-animation 2s ease;
}

.portfolio-details-modal-snapshots {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
}

.portfolio-details-modal-snapshot img {
	width: 100%;
	-webkit-animation: project-details-modal-animation 1s ease;
	animation: project-details-modal-animation 2s ease;
	transition: 0.3s all ease;
}

.portfolio-details-modal-snapshot img:hover {
	transform: scale(1.2, 1.2);
}

@keyframes project-details-modal-animation {
	0%,
	50% {
		transform: translateY(15px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@media screen and (max-width: 1366px) {
	.portfolio-details-modal-title-icon {
		height: 45px;
	}

	.portfolio-details-modal-title-icon svg {
		width: 28px;
		height: 28px;
	}

	.portfolio-details-modal-title {
		font-size: 1.8rem;
	}

	.portfolio-details-modal-description {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 1024px) {
	.portfolio-details-modal-btn {
		margin: 1.8rem 0 4rem;
	}

	.portfolio-details-modal-title-icon {
		height: 40px;
	}

	.portfolio-details-modal-title-icon svg {
		width: 24px;
		height: 24px;
	}

	.portfolio-details-modal-title {
		font-size: 1.5rem;
	}

	.portfolio-details-modal-description {
		font-size: 1.1rem;
	}

	.portfolio-details-modal-snapshots {
		grid-gap: 15px;
	}
}

@media screen and (max-width: 768px) {
	.portfolio-details-modal-title-icon {
		height: 34px;
	}

	.portfolio-details-modal-title-icon svg {
		width: 24px;
		height: 24px;
	}

	.portfolio-details-modal-btn {
		margin: 1.6rem 0 3.5rem;
	}

	.portfolio-details-modal-snapshots {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media screen and (max-width: 700px) {
	.portfolio-details-modal-title-icon {
		height: 26px;
	}

	.portfolio-details-modal-title-icon svg {
		width: 22px;
		height: 22px;
	}

	.portfolio-details-modal-title {
		font-size: 1.3rem;
	}

	.portfolio-details-modal-description {
		font-size: 1rem;
	}

	.portfolio-details-modal-snapshots {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 550px) {
	.portfolio-details-modal-title-icon {
		height: 30px;
	}

	.portfolio-details-modal-snapshots {
		grid-template-columns: repeat(2, 1fr);
	}

	.portfolio-details-modal-btn {
		margin: 1.5rem 0 2.5rem;
	}
}
